import { AxiosResponse } from 'axios';
import { ReviewRepositoryInterface } from '../types/base';
import { Review } from '../types/types';
import DataRepository from './DataRepository';

export default function ReviewRepository(): Readonly<ReviewRepositoryInterface> {
	const { post, get, update } = DataRepository();

	// const getReview = (query: string): Promise<AxiosResponse> => {
	// 	const endPoint = `api/reviews${query}`;
	// 	return get(endPoint);
	// };
	const getReview = (): Promise<AxiosResponse> => {
		const endPoint = `api/reviews`;
		return get(endPoint);
	};

	const postReview = (partyId: number, param: Review): Promise<AxiosResponse> => {
		const endPoint = `api/reviews?partyId=${partyId}`;
		return post(endPoint, param);
	};

	const subscriptions = (userId: number): Promise<AxiosResponse> => {
		const endPoint = `api/subscriptions?userId=${userId}`;
		return get(endPoint);
	};

	return Object.freeze({
		getReview,
		postReview,
		subscriptions,
	});
}
