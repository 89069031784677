import React from 'react';
import { Modal } from 'react-bootstrap';
import './Popup.css';

interface Props {
	header: React.ReactNode;
	children: any;
	show: boolean;
}

const Popup: React.FC<Props> = (props) => {
	const { header, children, show } = props;

	return (
		<Modal show={show} backdrop="static" size="sm" centered className="text-center custom-popup">
			<Modal.Body>
				<h1 style={{ color: 'var(--gray-9)', fontSize: '18px', fontWeight: '700', marginBottom: '10px' }}>
					{header}
				</h1>
				{children}
			</Modal.Body>
		</Modal>
	);
};

export default Popup;
