import moment from 'moment';
import { Nullable } from './types/base';

export default function Filter() {
	const transformToPhonNumber = (number: string): string => {
		return (
			number
				.replace(/[^0-9]/g, '')
				.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3')
				// eslint-disable-next-line no-useless-escape
				.replace(/(\-{1,2})$/g, '')
		);
	};

	const dateTimeLocal = (data: string): string => {
		if (data) {
			const date = new Date(data);
			return moment(date).format('YYYY-MM-DDTHH:mm:ss');
		}
		return '';
	};

	const toDate = (datetime: Nullable<string>): Nullable<string> => {
		return datetime ? datetime.split('T')[0] : null;
	};

	const toTime = (datetime: string): string => {
		return datetime.split('T')[1];
	};

	const getDayOfWeek = (date: any): string => {
		const week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
		const dayOfWeek = week[new Date(date).getDay()];

		return dayOfWeek;
	};

	const numberComma = (x: Nullable<number>): Nullable<string | number> => {
		return x !== null ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
	};

	return Object.freeze({
		transformToPhonNumber,
		dateTimeLocal,
		toDate,
		toTime,
		getDayOfWeek,
		numberComma,
	});
}
