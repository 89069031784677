import { AxiosResponse } from 'axios';
import { Nullable, PartyRepositoryInterface } from '../../types/base';
import { PartyFormInterface } from '../../types/types';
import DataRepository from '../DataRepository';

export default function PartyRepository(): Readonly<PartyRepositoryInterface> {
	const { post, get, update } = DataRepository();

	const getParties = (query: string): Promise<AxiosResponse> => {
		const endPoint = `api/parties`;
		return get(endPoint, query);
	};

	const closeParty = (id: number): Promise<AxiosResponse> => {
		const endPoint = `api/parties/${id}/close`;
		return post(endPoint, true);
	};

	const createParty = (param: PartyFormInterface): Promise<AxiosResponse> => {
		const endPoint = `api/parties`;
		return post(endPoint, param, true);
	};

	const getParty = (id: number): Promise<AxiosResponse> => {
		const endPoint = `api/parties/${id}`;
		return get(endPoint);
	};

	const updateParty = (id: number, param: PartyFormInterface): Promise<AxiosResponse> => {
		const endPoint = `api/parties/${id}`;
		return update(endPoint, param, 'PATCH');
	};

	const submitApplication = (param: { note: Nullable<string>; partyId: number }): Promise<AxiosResponse> => {
		const endPoint = 'api/subscriptions';
		return post(endPoint, param, true);
	};

	return Object.freeze({
		closeParty,
		getParties,
		createParty,
		getParty,
		updateParty,
		submitApplication,
	});
}
