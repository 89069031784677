import React from 'react';
import Container from '../components/container/Container';
import './css/Login.css';

const TermsOfService = (): JSX.Element => {
	return (
		<Container>
			<h2 className="title">이용약관</h2>
			<p>제1장 총칙</p>
			<p>제1조 (목적)</p>
			<p>
				본 약관은 가자고(이하 회사)가 인터넷 사이트(https://www.gajagotrip.com)를 통하여 제공하는 F&amp;B 및
				엑티비티 기반 소규모 모임 등 제반 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타
				필요한 사항을 규정함을 목적으로 합니다.
			</p>
			<p>제2조 (정의)</p>
			<p>
				본 약관에서 사용하는 용어의 정의는 다음과 같으며, 본조에서 정의되지 않은 용어는 일반적인 용례에
				따릅니다.
			</p>
			<p>
				1. 서비스 : 회원이 온라인 홈페이지를 통하여 본 약관에 따라 이용할 수 있는 회사가 제공하는 모든 서비스를
				의미합니다.
			</p>
			<p>
				2. 홈페이지 : 본 약관에 따라 회사가 제공하는 서비스가 구현되는 온라인상의
				웹페이지(https://www.gajagotrip.com)를 말합니다.
			</p>
			<p>3. 회원 : 이 약관에 따라 회사와 서비스 이용계약을 체결한 자를 말합니다.</p>
			<p>4. 서비스 이용료 : 레스토랑, 바(bar), 엑티비티를 통한 만남에 대해 회사에 지불하는 금전을 말합니다.</p>
			<p>7. 업체 정산료 : 회사가 서비스 이용료의 일부를 제휴 업체에게 정산하는 금전을 말합니다.</p>
			<p>
				8. 가자고 플랫폼 수수료: 제휴 업체 방문 이용료 중에서 정산료를 제외하고 회사에 지급되는 수수료를
				말합니다.
			</p>
			<p>
				9. 아이디(ID) : 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자와 숫자의 조합을
				의미합니다.
			</p>
			<p>
				10. 비밀번호 : 회원이 부여 받은 아이디와 일치되는 회원임을 확인하고 비밀보호를 위해 회원 자신이 정한
				문자 또는 숫자의 조합을 의미합니다.
			</p>
			<p>
				11. 게시물 : 회원이 서비스를 이용함에 있어 홈페이지의 게시판에 게재한
				부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을
				의미합니다.
			</p>
			<p>
				12. 공식 연락 채널 : 트립히어로 대표 이메일, 대표전화, 그 외 온라인 고객상담채널, 인스타그램 공식 계정
				@gajago_official 등 회사가 지정한 회사의 연락 채널을 의미합니다.
			</p>
			<p>&nbsp;</p>
			<p>제2장 서비스의 이용</p>
			<p>&nbsp;</p>
			<p>제3조 (서비스의 제공)</p>
			<p>&nbsp;</p>
			<p>1. 본 약관에 따라 회원이 된 자는 자사와 제휴한 업체에 방문하여 회사의 서비스를 이용할 수 있습니다.</p>
			<p>
				2. 회원은 제1항의 서비스를 이용하기 위하여 개별약관에 따른 새로운 약정을 회사와 체결하여야 할 수
				있습니다. 이 경우 각 서비스의 구체적인 내용은 서비스 별 개별약관에서 정한 바에 따릅니다.
			</p>
			<p>
				3. 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 다만, 회사는 서비스를 일정범위로 분할하여 각
				범위 별로 이용가능시간을 별도로 지정할 수 있으며 이러한 경우에는 그 내용을 사전에 공지합니다.
			</p>
			<p>
				4. 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우
				서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 회원에게 통지합니다. 다만, 회사가 사전에
				통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
			</p>
			<p>
				5. 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에
				공지한 바에 따릅니다.
			</p>
			<p>&nbsp;</p>
			<p>제4조 (서비스의 변경)</p>
			<p>&nbsp;</p>
			<p>
				1. 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를
				변경할 수 있습니다.
			</p>
			<p>
				2. 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및
				제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시됩니다.
			</p>
			<p>
				3. 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수
				있으며, 이에 대하여 관계법령에 특별한 규정이 없는 한 회원에게 별도의 보상 및 배상을 하지 않습니다.
			</p>
			<p>제5조 (이용계약의 체결)</p>
			<p>
				1. 회사가 본 약관에 따라 제공하는 서비스를 이용하고자 하는 자(이하 &ldquo;이용신청자&rdquo;)는 회사와
				서비스이용계약(이하 &ldquo;이용계약&rdquo;)을 체결하여 회원가입에 따른 회원의 자격을 부여받아야 합니다.
			</p>
			<p>
				2. 이용신청자가 본 약관의 내용에 대하여 동의를 한 다음 회사가 제공하는 양식에 따라 정보입력을 하고
				본인확인을 위한 인증절차를 이행하는 방법으로 회원가입신청을 하면 서비스에 대한 이용신청(이하
				&ldquo;이용신청&rdquo;)이 있는 것으로 보며, 이용신청에 대하여 회사가 승낙함으로써 이용계약이 체결됩니다.
			</p>
			<p>3. 제2항의 이용신청 절차와 방법은 회원이 개인(자연인)인 경우와 법인인 경우에 따라 다를 수 있습니다.</p>
			<p>
				4. 회사는 관계 법령에 따라 이용신청자가 제1항에 따라 입력한 정보에 대한 사실 확인을 위하여 필요한 경우
				이용신청자에게 증빙자료의 제출을 요청할 수 있으며, 이용신청자는 이에 따라 증빙자료를 제출하여야 합니다.
			</p>
			<p>5. 회사는 다음 각 호에 해당하는 이용신청에 대하여는 승낙을 하지 않을 수 있습니다.</p>
			<p>
				① 이용신청자가 이전에 본 약관에 의하여 회원자격을 상실한 적이 있는 경우(회사의 회원 재가입 승낙을 얻은
				경우 제외)
			</p>
			<p>
				② 이용신청자가 본 약관에 의하여 이전에 회사로부터 서비스 이용제한 조치를 받은 상태에서 이용계약을
				해지하고 다시 이용신청을 한 경우
			</p>
			<p>③ 제2항의 이용신청 시 실명이 아니거나 타인의 명의를 이용하여 이용신청을 한 경우</p>
			<p>④ 제2항의 이용신청 시 필요한 정보를 입력하지 않거나 허위의 정보를 기재한 경우</p>
			<p>⑤ 제4항에 따라 요청받은 사실확인을 위한 증빙자료를 제출하지 않은 경우</p>
			<p>⑥ 14세 미만 아동이 법정대리인의 동의를 얻지 아니한 경우</p>
			<p>⑦ 사회의 안녕과 질서, 미풍양속을 저해할 우려가 있는 경우</p>
			<p>⑧ 타인의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협할 우려가 있는 경우</p>
			<p>⑨ 위법 또는 부당한 목적으로 이용신청을 한 경우</p>
			<p>⑩ 본 약관을 위반하며 신청하는 경우</p>
			<p>6.회사는 다음 각 호의 경우에 승낙을 유보할 수 있습니다.</p>
			<p>① 제공하는 서비스 관련 설비의 용량이 부족한 등 여유가 없는 경우</p>
			<p>② 기타 서비스 제공을 위한 재정적, 기술적 문제가 있다고 판단되는 경우</p>
			<p>
				7. 회사는 본 약관 및 개별약관에서 정한 바에 따라 회원 별로 서비스의 이용시간, 이용횟수, 메뉴 등을
				세분하여 달리 적용할 수 있습니다.
			</p>
			<p>
				8. 회사는 회원에 대하여 「영화 및 비디오물의 진흥에 관한 법률」 및 「청소년 보호법」, 「게임산업진흥에
				관한 법률」 등 관련 법률에 따른 등급 및 연령 준수를 위해 이용제한이나 등급 별 제한을 할 수 있습니다.
			</p>
			<p>제6조 (서비스 이용료 및 정산료)</p>
			<p>
				1. 회사는 제3조에 따른 서비스를 이용하는 가입 회원으로부터 가자고 서비스 이용료를 지급 받으며, 이
				약관에서 정하는 바에 따라 제휴 업체에게 정산료를 지급해야 합니다.
			</p>
			<p>
				2. 회원이 타 유료서비스를 이용하는 경우에 부과되는 일체의 요금은 회원이 해당 서비스 업체에 지불하여야
				합니다.
			</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>제7조 (취소/환불)</p>
			<p>1.회원의 사정으로 제휴 업체 방문일 이전에 방문을 취소할 경우 아래의 규정에 따라 환불처리합니다.</p>
			<p>① 방문일로부터 7일 전까지 취소 시 : 전액환불</p>
			<p>② 방문일로부터 6일 전 ~ 2일 전까지 취소 시 : 50% 환불</p>
			<p>③ 방문일로부터 1일 전 ~ 당일 취소 시 : 환불불가</p>
			<p>2.취소 및 환불에 대한 문의는 다음 담당자를 통해서 하실 수 있습니다.</p>
			<p>① 운영 담당자 : 고소연 (010 5314 8907)</p>
			<p>
				3.취소 요청은 즉시 접수가 되나, 환불의 경우 카드사 사정에 따라 7~10영업일 정도의 취소기간이 소요될 수
				있습니다. 카드대금 결제일에 따라 청구작업기간이 다를 수 있으며, 자세한 내용은 해당 카드사에서 확인해야
				합니다. (단, 주말, 공휴일은 제외)
			</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>제8조 (회원의 이용계약 해지 등)</p>
			<p>
				1.회원은 서비스 중단을 원할 경우 회사가 제공하는 양식에 따라 회원탈퇴신청을 하면 이용계약 해지의
				의사표시를 한 것으로 보며, 회사에 해지의 의사표시가 도달한 때에 이용계약의 해지효력이 발생합니다.
			</p>
			<p>
				2.해지효력이 발생한 때로부터 회원은 회원의 자격을 상실하며, 회사가 제공하는 모든 서비스를 이용할 수 없게
				됩니다.
			</p>
			<p>
				3.제1항에도 불구하고 회원이 개별약관에 따라 회사의 개별 서비스를 이용하고 있는 경우에는 회원탈퇴신청이
				제한될 수 있습니다.
			</p>
			<p>
				4.제2항에도 불구하고 이용계약의 해지효력이 발생할 당시 당해 회원이 회사가 제공하는 서비스를 통해 진행
				중인 거래 또는 절차가 있다면 당해 서비스에 관한 개별약관 또는 회사의 안내에 따라 정산절차를 거쳐야
				합니다.
			</p>
			<p>
				5.회원이 제1항과 제2항에 따라 이용계약을 해지하여 회원자격을 상실하게 될 경우, 당해 회원은 본 약관에
				따라 다시 회원가입을 신청하는 데 있어 제한을 받을 수 있습니다.
			</p>
			<p>
				6.회원이 제1항과 제2항에 따라 이용계약을 해지할 경우, 관계법령 및 개인정보처리방침에 따라 회사가
				회원정보를 보유하는 경우를 제외하고는 회원의 모든 데이터는 소멸됩니다. 다만, 이 경우에도 타인에 의해
				담기, 스크랩 등이 되어 재게시되거나, 공용게시판에 등록된 게시물 등은 삭제되지 않을 수 있습니다.
			</p>
			<p>&nbsp;</p>
			<p>제9조 (회사의 이용계약의 제한, 해지 등)</p>
			<p>
				1. 회사는 회원에게 다음 각 호의 해지사유가 있는 경우 일정 기간 내에 그 해지사유의 시정을 요구하고, 정한
				시정 기간 내에 회원이 이를 시정하지 아니하면 회사는 이용계약을 해지 할 수 있습니다. 단, 아래 제11호와
				같은 경우 회사의 심각한 손해를 미연에 방지하기 위해서 시정 기간 없이 즉시 이용계약을 해지할 수 있습니다.
			</p>
			<p>① 제5조 제5항 각 호에서 정한 승낙거부사유가 있음이 밝혀진 경우</p>
			<p>② 회원이 공공질서 및 미풍양속에 위배되는 거래행위를 하거나 시도한 경우</p>
			<p>③ 회원이 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우</p>
			<p>
				④ 주민등록법을 위반한 명의도용 및 결제도용, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의
				제공 및 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 &quot;정보통신망법&quot;)을 위반한
				불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등 관계법령을 위반한 경우
			</p>
			<p>⑤ 제3자의 결제수단이나 개인정보를 도용한 경우</p>
			<p>⑥ 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해행위를 한 경우</p>
			<p>⑦ 회사 및 기타 제3자의 권리나 명예, 신용 기타 정당한 이익을 침해하는 행위를 한 경우</p>
			<p>
				⑧ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 홈페이지에 공개 또는 게시하는
				행위
			</p>
			<p>⑨ 회사의 동의 없이 영리를 목적으로 서비스를 이용한 경우</p>
			<p>
				⑩ 회사의 공식 연락 채널 혹은 회사 직원의 개인 연락처를 통해 폭언, 욕설, 성희롱, 반복적인 민원 제기
				등으로 회사의 업무 방해한 경우
			</p>
			<p>⑪ 기타 범죄에 연루되는 등 회사에 심각한 손해를 일으킬 수 있는 행위를 한 경우</p>
			<p>⑫ 기타 위법하거나 부당한 목적으로 서비스를 이용한 경우</p>
			<p>⑬ 본 약관에 위반하는 행위를 한 경우</p>
			<p>
				⑭ 그 밖에 회원과의 계약을 유지하기 어려운 중대한 사유가 있어 회사가 이용계약을 유지하는 것이 객관적으로
				어려운 경우
			</p>
			<p>
				2. 제1항의 경우에도 불구하고 회사는 제1항 각호의 사유가 시정이 가능하다고 인정되는 경우 회원에게 그
				사유를 통지를 하고 일정한 기간 동안 회원에게 경고, 일시정지, 영구이용정지 등으로 서비스의 이용을
				단계적으로 제한할 수 있습니다. 다만, 개별 서비스의 이용제한에 관한 조건 및 세부내용은 개별 서비스상의
				개별약관 또는 회사가 미리 고지한 정책에서 정하는 바에 의합니다.
			</p>
			<p>
				3. 제1항에 따라 이용계약이 해지된 경우 해지되기 전까지 회원이 서비스 이용을 통해 획득한
				&quot;포인트&quot; 및 기타 혜택 등도 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.
			</p>
			<p>
				4. 본 조에 따라 서비스 이용을 제한하거나 이용계약을 해지하는 경우에는 회사는 회원에게 공식 연락 채널을
				통해 통지합니다.
			</p>
			<p>
				5. 회원은 제1항과 제2항에 따른 이용해지 또는 이용제한 등에 대하여 회사가 제공하는 양식과 절차에 따라
				이의신청을 할 수 있습니다. 이 경우 이의신청이 정당하다고 회사가 인정하는 경우 회사는 서비스의 이용을
				재개합니다. 단, 법적 문제 등 이의신청에 대해 회사에서 자체적으로 판단하기 어려운 경우 회사는 회원에게
				임시적으로 서비스 이용 중단을 통보할 수 있습니다.
			</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p>제3장 회원정보의 보호 및 관리</p>
			<p>제10조 (회원정보의 수집과 보호)</p>
			<p>
				1. 회사는 서비스 제공을 위하여 이용계약의 체결 시 필요한 정보(이하 &ldquo;회원정보&rdquo;)를 수집할 수
				있으며, 그 외에도 수집목적 또는 이용목적을 밝혀 회원으로부터 정보를 수집할 수 있습니다. 이 경우 회사는
				회원으로부터 정보수집에 대한 동의를 받으며, 회원은 정보제공에 동의를 한 이후에도 회사가 제공하는 양식에
				따라 그 동의를 철회할 수 있습니다.
			</p>
			<p>
				2. 회원의 개인정보의 수집, 보호 및 사용에 대해서는 관계법령 및 회사의 개인정보처리방침이 적용됩니다.
				다만, 회사의 홈페이지 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
			</p>
			<p>
				3.관계 법령에 따라 회원정보의 이용과 제3자에 대한 정보제공을 허용하고 있는 경우를 제외하고 회사는
				제1항에 따라 회원으로부터 동의를 받은 목적 외에 회원의 동의 없이 다른 제3자에게 회원정보를 제공하지
				않습니다. 다만, 회사의 개인정보처리방침에서 정하고 있는 제3자에 대한 정보제공 및 제3자 정보 위탁과
				관련하여서는 회원이 모두 동의하였고, 이에 대하여 회사를 상대로 이의를 제기하지 않습니다.
			</p>
			<p>4. 회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.</p>
			<p>&nbsp;</p>
			<p>제11조 (회원의 회원정보 관리에 대한 의무)</p>
			<p>1. 회원의 아이디와 비밀번호를 포함한 회원정보에 관한 관리책임은 회원에게 있습니다.</p>
			<p>
				2.회사는 회원의 아이디가 개인정보 유출의 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및
				회사의 운영자로 오인할 우려가 있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.
			</p>
			<p>
				3. 회원은 주민등록번호, 동일숫자, 연속숫자 등의 비밀번호를 사용하지 말아야 하며 타인이 쉽게 추측할 수
				있는 비밀번호 사용을 자제하여 관리에 유의하여야 합니다.
			</p>
			<p>
				4. 회원은 아이디, 비밀번호 등을 포함한 회원정보를 타인에게 누설하여서는 아니 되며, 만약 누설하였을 경우
				지체 없이 회사에게 이러한 사실을 회사의 공식 연락 채널을 통해 통지하여 알려야 합니다.
			</p>
			<p>5. 회원은 회원정보를 제3자가 양도, 대여, 이용하도록 하여서는 안 됩니다.</p>
			<p>
				6. 회원은 제3자에 의해 회원정보가 도용되거나 사용되고 있음을 인지한 경우에는 이를 즉시 회사에 제4항과
				같은 방법으로 통지하고 회사의 안내에 따라야 합니다.
			</p>
			<p>
				7. 제4항과 제6항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에
				따르지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.
			</p>
			<p>
				8. 회원은 서비스를 이용함으로써 얻은 다른 회원의 개인정보를 서비스 이용 이외의 목적으로 사용해서는
				안되며 제 3자에게 제공해서는 안됩니다.
			</p>
			<p>제12조 (쿠키(Cookie)의 운용 및 활용)</p>
			<p>
				1. 회사는 회원에게 적합하고 유용한 서비스를 제공하기 위해서 회원의 정보를 저장하고 수시로 불러오는
				쿠키(cookie)를 이용합니다.
			</p>
			<p>
				2. 제1항과 관련하여 회원은 쿠키이용에 대한 선택권을 가지며 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여
				경고하도록 이용하는 컴퓨터 브라우저의 설정을 변경할 수 있습니다. 다만, 쿠키의 저장을 거부할 경우,
				로그인이 필요한 서비스를 이용할 수 없게 됨으로써 발생되는 문제에 대한 책임은 회원에게 있습니다.
			</p>
			<p>&nbsp;</p>
			<p>제13조 (회원정보의 변경)</p>
			<p>
				1. 회원은 서비스 내 &ldquo;회원정보&rdquo; 화면을 통하여 회원의 정보를 열람하고 수정할 수 있습니다.
				다만, 서비스의 제공 및 관리를 위해 필요한 실명, 주민등록번호, 아이디 등은 수정이 불가능할 수 있습니다.
			</p>
			<p>
				2. 회원은 회원가입신청 시 입력한 사항이 변경되었을 경우 온라인으로 수정을 하거나 회사의 공식 연락 채널
				등으로 회사에 대하여 그 변경사항을 알려야 하며, 이와 같이 변경사항을 회사에 알리지 않아 발생한 불이익에
				대하여 회사는 책임지지 않습니다.
			</p>
			<p>제4장 기타</p>
			<p>제14조 (회원의 의무)</p>
			<p>1. 회원은 다음 행위를 하여서는 안됩니다.</p>
			<p>
				① 게시글 등록 시의 허위 또는 타인의 권리(저작권 및 초상권을 포함하며, 이에 한하지 않음)을 침해한
				내용으로 작성
			</p>
			<p>② 기타 게시물의 등록, 변경 시 허위내용의 작성</p>
			<p>③ 타인의 정보 도용</p>
			<p>④ 회사가 게시한 정보의 변경</p>
			<p>⑤ 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</p>
			<p>⑥ 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
			<p>⑦ 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
			<p>
				⑧ 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위
			</p>
			<p>⑨ 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</p>
			<p>
				⑩ 서비스에 과부하 및 기타 장애를 일으키거나 서비스의 운영을 고의로 방해하는 컴퓨터 바이러스 감염 자료
				또는 정보를 게시, 유포 또는 이메일로 발송하거나, 서비스에 게시된 정보를 변경하는 행위
			</p>
			<p>⑪ 회사 또는 제3자로 가장하는 행위 및 회사 또는 제3자와의 관계를 허위로 명시하는 행위</p>
			<p>
				⑫ 다른 회원의 개인정보를 수집, 이용, 제공하는 행위(회원은 개인정보처리방침 및 본 약관에 의해서 다른
				회원의 개인정보를 수집하였을 경우 이용목적이 종료한 경우 폐기해야함)
			</p>
			<p>⑬ 기타 관련 법령을 위반하거나 불법적이거나 부당한 행위</p>
			<p>
				2. 회원은 서비스를 이용하여 얻은 정보를 회사의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의
				방법으로 사용하거나 이를 타인에게 제공 및 공유 할 수 없습니다.
			</p>
			<p>
				3. 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항
				등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.
			</p>
			<p>
				4. 회원은 이를 위반하여 발생하는 모든 손실, 손해에 대한 민사 및 형사 책임을 부담하며, 회사를 완전히
				면책시켜야 합니다.
			</p>
			<p>5. 회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여, 담보 제공할 수 없습니다.</p>
			<p>
				6. 회원은 회사의 서비스 제공 사이트에 등록된 본인의 회원 계정에 관한 접근 권한 및 접근 수단을 안전하게
				관리하여야 하며, 회원의 귀책 사유로 인하여 발생한 계정의 도용과 서비스의 이용에 대한 책임을 부담합니다.
			</p>
			<p>제15조 (회원에 대한 통지)</p>
			<p>
				1. 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 서비스 내 회원의 이메일 주소 등으로
				할 수 있습니다.
			</p>
			<p>
				2.회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 홈페이지에 게시함으로써 제1항의 통지에 갈음할 수
				있습니다.
			</p>
			<p>제16조 (정보의 제공 및 광고의 게재)</p>
			<p>
				1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 이메일 등의 방법으로 회원에게 제공할
				수 있습니다. 다만, 회원은 관계법령에 따른 거래 관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는
				언제든지 해당 정보 수신에 대하여 거절할 수 있습니다.
			</p>
			<p>
				2.회사는 서비스화면, 홈페이지, 이메일 등에 회사 또는 제휴업체의 광고를 게재할 수 있습니다. 다만, 회원은
				광고가 게재된 이메일의 수신에 대하여 거절할 수 있습니다.
			</p>
			<p>제17조 (게시물의 관리 등)</p>
			<p>1. 회원이 홈페이지 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</p>
			<p>
				2. 회원이 홈페이지 내에 게시하는 게시물은 검색결과 내지 서비스 및 관련 프로모션 등에 의해 노출될 수
				있으며, 회사가 회사의 홍보 및 마케팅을 위해서 이용 및 활용할 수 있습니다. 해당 노출, 이용 및 활용을 위해
				필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있으며 이에 대해서 회원은 동의하고 이의를
				제기하지 못하며 저작권 침해 등 관련 법률 위반을 주장할 수 없습니다. 이 경우 회사는 저작권법 규정을
				준수하며, 회원은 회사의 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외,
				비공개 등의 조치를 회사가 정한 절차에 따라 요구할 수 있습니다
			</p>
			<p>3.회사는 제2항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 회원의 동의를 받습니다.</p>
			<p>
				4. 회원의 게시물이 정보통신망법 및 저작권법 등 관계법령에 위반되는 내용을 포함하거나 권리침해가 인정될
				만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에 회사는 삭제, 비공개 등의 조치를 취할 수
				있습니다.
			</p>
			<p>제18조 (서비스 제공 관련 분쟁 조정)</p>
			<p>
				1. 회사는 통신판매중개자로서 회원들에게 제공하는 서비스는 온라인 거래장소(marketplace)를 제공하거나
				안전한 결제 수단을 제공하고 기타 부가정보를 제공한다.
			</p>
			<p>2.아래의 경우로 문제가 발생한 경우, 환불/취소에 대한 분쟁의 조정은 1차적으로 회사에서 책임집니다.</p>
			<p>① 회원이 제공받을 서비스 (모임의 주제, 공간 구성 등) 가 사이트에 명시된 것과 상이할 경우</p>
			<p>② 서비스 제공 과정에서 문제가 발생한 경우</p>
			<p>
				3.단, 회사에서 분쟁 조정이 어렵다고 판단되는 경우 회사는 하기의 외부기관들에 분쟁건을 이관할 수 있으며,
				이관된 시점 이후부터는 분쟁조정권고 등 이관된 기관의 의견을 신뢰하며 이를 기준으로 분쟁관련 업무를
				처리합니다.
			</p>
			<p>&bull; 대한상사중재원 ( http://www.kcab.or.kr/intro.jsp )</p>
			<p>&bull; 한국소비자원 ( http://www.kca.go.kr/ )</p>
			<p>&bull; 한국공정거래조정원 ( http://www.kofair.or.kr/goMain.do )</p>
			<p>&bull; 정보통신산업진흥원 전자문서&middot;전자거래분쟁조정위원회 ( http://www.ecmc.or.kr )</p>
			<p>제19조 (면책 조항)</p>
			<p>1. 회사는 회원들이 이용할 수 있는 서비스를 제공할 뿐이며 회원을 대리하지 않습니다.</p>
			<p>
				2.회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 회원에게 서비스를 제공할 수 없는 경우에는 서비스
				제공에 관한 책임이 면제됩니다.
			</p>
			<p>3. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</p>
			<p>
				4.회사는 회원간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임을 지지
				않습니다.
			</p>
			<p>
				5. 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 책임지지
				않습니다.
			</p>
			<p>6. 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</p>
			<p>
				7. 회원이 본 약관의 규정에 위반한 행위로 회사 및/또는 제3자에게 손해를 입히거나 회원의 책임있는 사유에
				의해 회사 및/또는 제3자에게 손해를 입힌 경우 회원은 그 손해를 배상하여야 하고, 회원은 회사를 면책시켜야
				합니다.
			</p>
			<p>제20조 (약관의 게시와 개정)</p>
			<p>1. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.</p>
			<p>
				2.회사는 약관의 규제에 관한 법률, 정보통신망법 등 관계법령을 위반하지 않는 범위에서 본 약관을 개정할 수
				있습니다.
			</p>
			<p>
				3. 회사가 약관을 개정할 경우에는 적용일자를 명시하여 제1항의 방법에 따라 그 개정약관을 7일 이상
				공지합니다. 다만, 회원에게 불리한 내용으로 약관의 개정이 있는 경우에는 별도의 전자적 수단을 통해 따로
				명확히 통지하도록 합니다.
			</p>
			<p>
				4.회사가 제3항에 따라 개정약관을 공지 또는 통지하면서 회원에게 7일 기간 내에 의사표시를 하지 않으면
				의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의
				의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
			</p>
			<p>
				5. 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용하지 않으며, 이 경우 회원은
				제8조에서 정한 바에 따라 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이
				있는 경우에는 회사가 동의하지 않은 회원과의 이용계약을 해지할 수 있습니다.
			</p>
			<p>제21조 (약관의 효력 및 해석)</p>
			<p>
				1. 회사는 회사가 제공하는 개별 서비스에 대해서는 별도의 이용약관 및 운영정책, 세부지침(이하
				&quot;개별약관 등&quot;)을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 &quot;개별약관 등&quot;이
				우선하여 적용됩니다.
			</p>
			<p>
				2. 본 약관에서 정하지 아니한 사항이나 해석에 대해서는 &quot;개별약관 등&quot; 및 관계법령 또는 상관례에
				따릅니다.
			</p>
			<p>제22조 (준거법 및 재판관할)</p>
			<p>1. 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.</p>
			<p>
				2. 회사와 회원 간 발생한 분쟁에 관한 소송은 민사소송법에 따른 전속적 합의관할에 따라 대한민국
				서울중앙지방법원을 전속적 관할법원으로 합니다.
			</p>
			<p>
				3. 해외에 주소나 거소가 있는 회원의 경우에도 회사와 회원간 발생한 분쟁에 관한 소송은 대한민국 소재
				서울중앙지방법원을 전속적 관할법원으로 합니다.
			</p>
			<p>&nbsp;</p>
			<p>가자고 개인정보 처리방침</p>
			<p>1. 개인정보 처리방침의 기본방향</p>
			<p>
				가자고 서비스를 제공하는 트립히어로 플랫폼 (이하 &ldquo;회사&rdquo;)은 서비스 이용을 위해 회원이
				제공하신 개인 정보를 매우 중요하게 여기고, 개인정보 처리방침을 수립하여 이를 엄격히 준수하고 있습니다.
				회사의 개인정보 처리방침은 관련 법령 및 정보통신부가 제정한 개인정보보호지침을 준수하고 있으며,
				개인정보가 유출되어 피해를 입지 않도록 개인정보 보호에 최선을 다할 것입니다. 회원 여러분은 언제든지 이
				처리방침을 열람하여 확인하실 수 있으며, 개인정보 처리방침은 법령 및 정부지침의 변경과 보다 나은 서비스
				제공을 위해서 그 내용이 변경될 수 있습니다.
			</p>
			<p>2. 목차</p>
			<p>
				회사의 개인정보처리방침(이하 &ldquo;본 방침&rdquo;)은 다음과 같은 내용을 담고 있습니다.
				<br />
				&bull; 개인정보의 수집목적 및 이용목적
				<br />
				&bull; 개인정보의 수집범위 및 수집방법
				<br />
				&bull; 개인정보의 이용 및 제공
				<br />
				&bull; 개인정보에 대한 회원의 권리 및 행사방법
				<br />
				&bull; 개인정보의 보유 및 이용기간
				<br />
				&bull; 장기 미이용 회원 개인정보 분리 보관
				<br />
				&bull; 개인정보 보호에 대한 기술적 관리적 대책
				<br />
				&bull; 개인정보의 파기절차 및 파기방법
				<br />
				&bull; 수집한 개인정보 취급 위탁
				<br />
				&bull; 개인정보 제3자 제공
				<br />
				&bull; 이용자 및 법정대리인의 권리와 그 행사방법
				<br />
				&bull; 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
				<br />
				&bull; 개인정보 보호책임자
				<br />
				&bull; 기타 개인정보처리방침에 관한 사항
			</p>
			<p>3. 개인정보의 수집목적 및 이용목적</p>
			<p>
				회사가 회원으로부터 개인정보를 수집하는 이유와 개인정보의 이용목적은 회사가 운영하는 사이트를 통해
				회원에게 최적의 서비스를 제공하기 위한 목적으로, 이에 필요한 개인정보를 수집하고 있습니다. 회원이 제공한
				개인정보는 회원에게 유용한 정보 제공이나 회원이 요청한 각종 의견에 대한 답변 제공 등에 사용되고
				있습니다.
			</p>
			<p>4. 개인정보의 수집범위 및 수집방법</p>
			<p>1. 수집범위</p>
			<p>
				◦ 회사는 적법하고 공정한 절차와 이용자의 동의를 받은 개인정보를 수집합니다. 이를 위해 개인정보 처리방침
				또는 이용약관의 내용에 대하여 「동의」버튼을 클릭할 수 있는 절차를 마련하여, 「동의」 버튼을 클릭하면
				개인정보 수집에 대해 동의한 것으로 봅니다.
				<br />◦ 회사가 수집하는 개인정보 항목(필수항목) : 이름, 이메일주소, 비밀번호, 생년월일, 전화번호, 성별,
				나이, 주소
				<br />◦ 홈페이지 회원 가입 시 주민등록번호를 요구하지 않습니다.
				<br />◦ 회사는 회원의 기본적인 인권 침해 우려가 있는 민감한 개인정보는 수집하지 않습니다. 다만 이용자의
				적법한 동의가 있거나 법령의 규정에 의한 경우는 예외로 합니다.
				<br />
				2. 수집범위 : 홈페이지를 통한 회원 가입 및 변경, 문의 메일, 전화 상담 등
			</p>
			<p>3.서비스 이용과정이나 처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.</p>
			<p>◦ 수집내용 : IP Address, 쿠키, 방문기록, 서비스 이용기록, 불량이용기록</p>
			<p>5. 개인정보의 이용 및 제공</p>
			<p>1. 회사에서는 수집한 개인정보를 다음의 목적을 위해 이용할 수 있습니다.</p>
			<p>
				◦ 홈페이지 회원관리 : 서비스 이용 등에 따른 본인확인, 개인식별, 불량회원의 부정 이용방지와 비인가
				사용방지, 가입의사 확인, 가입 및 가입횟수 제한, 고지사항 전달, 상담처리 등
			</p>
			<p>◦ 이메일 서비스 제공</p>
			<p>◦ 설문조사와 이벤트를 위한 이용</p>
			<p>
				◦ 컨텐츠 및 회원 맞춤형 서비스 제공, 서비스 구매 및 요금결제, 금융거래 본인인증 및 금융 서비스, 상품
				주문에 따른 배송 서비스
			</p>
			<p>
				◦ 신규서비스(콘텐츠) 개발 및 특화, 이벤트 등 광고성 정보 전달, 통계학적 특성에 따른 서비스 제공 및 광고
				게재, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계
			</p>
			<p>
				2. 회원이 제공한 개인정보는 당해 회원의 동의 없이는 회사가 이미 고지 또는 명시한 목적범위 이외로
				이용하거나 제3자에게 제공할 수 없습니다. 다만, 아래의 경우에는 예외로 합니다.
			</p>
			<p>◦ 수사를 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 등의 요구가 있는 경우</p>
			<p>
				◦ 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법,
				전기통신사업법, 지방세법, 소비자보호법, 한국은행법, 형사소송법 등 법령에 특별한 규정이 있는 경우
			</p>
			<p>
				◦ 법령의 규정에 근거하여 통계목적, 학술연구, 시장조사 등에 특정 개인을 식별할 수 없는 형태로 개인정보를
				제공하는 경우
			</p>
			<p>&nbsp;</p>
			<p>6. 개인정보에 대한 회원의 권리 및 행사방법</p>
			<p>1. 이용자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.</p>
			<p>① 개인정보 열람요구</p>
			<p>② 오류 등이 있을 경우 정정 요구</p>
			<p>③ 삭제요구</p>
			<p>④ 취급정지 요구</p>
			<p>
				<br />
				2. 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며
				회사는 이에 대해 지체없이 조치하겠습니다.
			</p>
			<p>
				3. 이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할
				때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
			</p>
			<p>
				4. 만 14세 미만 아동의 경우, 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을
				통하여 하실 수 있습니다. 이 경우, 법정대리인은 이용자의 모든 권리를 가집니다.
			</p>
			<p>
				5. 이용자는 정보통신망법, 개인정보보호법 등 관계법령을 위반하여 회사가 취급하고 있는 이용자 본인이나
				타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
			</p>
			<p>6. 개인정보의 보유 및 이용기간</p>
			<p>
				원칙적으로 개인정보 수집 및 이용목적이 달성된 후(회원탈퇴)에는 해당 정보를 지체 없이 파기합니다. 단,
				다음의 사유에 대해서는 회사 및 관계법령에 명시되어 있는 기간 동안 보유합니다. 만일, 아래 관계법령에서
				보존 기간에 대한 개정이 있는 경우 개정된 규정을 적용합니다.
				<br />
				1. 회사 내부 방침에 의한 정보보유 사유
				<br />① 이메일주소
				<br />▪ 보존 이유 : 불량회원의 부정이용방지와 비인가 사용방지 및 서비스 이용의 혼선 방지
				<br />▪ 보존 기간 : 1년
				<br />
				2. 관계법령에 의한 정보보유 사유
				<br />① 웹사이트 방문기록
				<br />▪ 보존 이유 : 통신비밀보호법
				<br />▪ 보존 기간 : 3개월
				<br />② 본인확인에 관한 기록
				<br />▪ 보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
				<br />▪ 보존 기간 : 6개월
				<br />③ 표시/광고에 관한 기록
				<br />▪ 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
				<br />▪ 보존 기간 : 6개월
				<br />④ 계약 또는 청약철회 등에 관한 기록
				<br />▪ 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
				<br />▪ 보존 기간 : 5년
			</p>
			<p>
				⑤ 대금결제 및 재화 등의 공급에 관한 기록
				<br />▪ 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
				<br />▪ 보존 기간 : 5년
				<br />⑥ 소비자의 불만 또는 분쟁처리에 관한 기록
				<br />▪ 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
				<br />▪ 보존 기간 : 3년
				<br />
				8. 장기 미이용 회원 개인정보 분리 보관
			</p>
			<p>
				1. 관련 법령에 의거하여 장기 미이용 회원의 계정을 휴면 상태로 전환하고 개인정보를 암호화하여 분리
				보관합니다.
			</p>
			<p>
				◦ 관련 법령 : 정보통신망이용촉진및정보보호등에관한법률 제29조 및 동법 시행령 제16조
				<br />◦ 휴면 회원 : 서비스를 1년이상 이용하지 않음에 따라 휴면 상태로 전환된 회원
				<br />
				2. 휴면 상태 전환 30일 전까지 휴면 예정 회원에게 휴면 전환 예정일, 별도 분리 보관되는 사실 및 개인정보
				항목이 이메일을 통해 사전 안내됩니다. 해당 통지 수단에 대한 정보가 부재 또는 오류인 경우에는 홈페이지
				공지사항 게시로 대체됩니다.
			</p>
			<p>
				3.휴면 예정 회원이 사전 안내된 휴면 전환 예정일 이전에 로그인 하시는 경우에는 휴면 상태로 전환되지 않고
				기존과 같이 서비스를 이용하실 수 있습니다.
			</p>
			<p>4. 휴면 상태로 전환된 사실은 메일을 통해 휴면 회원에게 안내됩니다.</p>
			<p>5. 휴면 회원은 사용했던 계정으로 로그인하여 서비스를 재이용함으로써 일반회원으로 전환될 수 있습니다.</p>
			<p>6. 휴면 회원의 개인정보는 4년간 별도 분리 보관 후 삭제됩니다.</p>
			<p>7. 개인정보 보호에 대한 기술적 관리적 대책</p>
			<p>
				1. 회사는 개인정보가 누출되지 않도록 기술적&bull;관리적 대책을 강구하는 데 최선을 다합니다.
				<br />
				2. 회사는 이용자의 개인정보를 취급함에 있어서 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록
				안정성 확보를 위하여 최선을 다하고 있습니다. 이를 위하여 비밀번호 등을 이용한 보안장치, 백신 프로그램을
				이용한 컴퓨터바이러스 방지장치, 암호알고리즘 등을 이용하여 네트워크 상에 개인정보를 안전하게 전송할 수
				있는 보안장치, 침입차단시스템 등을 이용한 접근통제장치, 기타 안정성 확보를 위하여 필요한 기술적 장치를
				확보하는 등의 기술적 대책을 강구하고 있습니다.
				<br />
				3. 회사는 개인정보 접근이 가능한 직원을 최소한으로 제한하고, 본 정책의 준수 및 기타 개인정보 보호를 위한
				지속적인 교육을 실시하고 있습니다. 또한 개인정보 접근을 위한 운영자 비밀번호를 정기적으로 갱신하여
				불법적인 정보유출을 막기 위해 최선을 다하고 있습니다.
				<br />
				4. 회사는 개인정보의 안정성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
				<br />◦ 기술적 조치 : 회원의 개인정보는 비밀번호에 의해 보호되며, 중요한 데이터는 파일 및 전송 데이터를
				암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 통해 보호하고 있습니다.
				<br />◦ 관리적 조치 : 개인정보관련 취급 직원은 담당자에 한정시켜 최소화 하고 개인정보보호 의무에 관해
				정기적인 교육을 실시하며 접근 권한을 관리하는 등 관리적 대책을 시행하고 있습니다.
			</p>
			<p>&nbsp;</p>
			<p>
				10. 개인정보의 파기절차 및 파기방법
				<br />
				이용자의 개인정보는 원칙적으로 본 방침에서 정한 보유 및 이용기간이 종료된 경우 지체없이 파기됩니다.
				회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
				<br />
				1. 파기절차
				<br />◦ 이용자의 개인정보는 본 방침에서 정한 보유 및 이용기간이 종료된 경우 파기됩니다. 단, 관련
				법령에서 일정한 기간 동안 보존을 요구하는 경우, 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 관련
				법령에서 정한 기간동안 저장된 후 파기됩니다.
				<br />◦ 다만, 회원 탈퇴 시에는 시스템 업데이트 소요시간으로 인해 탈퇴회원의 정보가 파기되는 시간이
				일정시간 소요될 수 있습니다.
				<br />
				2.파기방법
				<br />◦ 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
				<br />◦ 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
				<br />
				11. 수집한 개인정보 취급 위탁
				<br />
				회사는 보다 나은 서비스 제공과 이용자의 편의 제공 등 업무수행을 원활하게 하기 위해 이용자의 개인정보를
				동의 받은 이용목적 내에서 다음과 같이 타 업체에 수집, 보관, 취급 등을 위탁하여 취급하고 있습니다.
				이용자와의 서비스계약 이행을 위하여 회사가 개인정보 취급 위탁을 위탁하는 업체 이외에 제휴 마케팅을 통한
				개인정보의 취급을 위탁하는 경우에는 해당 서비스 화면에 수탁자, 개인정보 취급 위탁을 하는 업무의 내용에
				관한 사항을 홈페이지를 통해 미리 고지하고 동의를 획득하겠습니다.
			</p>
			<p>
				회사가 이용자의 개인정보를 위탁하는 현황은 다음과 같습니다.
				<br />
				제공대상자
				<br />
				위탁업무내용
				<br />
				제공정보
				<br />
				나이스정보통신(주)
				<br />
				휴대폰 번호를 통한 본인확인
				<br />
				생년월일, 성명
				<br />
				성별, 휴대폰번호
			</p>
			<p>&nbsp;</p>
			<p>
				12. 개인정보 3자 제공
				<br />
				1.회사는 계약의 이행을 위하여 최소한의 개인정보만 제공하고 있으며, 개인정보를 제3자에게 제공해야 하는
				경우 사전에 이용자에게 해당 사실을 알리고 동의를 구하도록 하겠습니다. 다만 다음의 경우는 예외로 하고
				있습니다.
				<br />
				가. 서비스 제공에 따른 요금정산을 위해 필요한 경우
				<br />
				나. 법령의 규정에 의한 경우
				<br />
				다. 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
			</p>
			<p>
				13. 이용자 및 법정대리인의 권리와 그 행사 방법
				<br />
				1. 이용자 및 만 14세 미만 아동의 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 아동의 개인정보를
				조회하거나 수정할 수 있으며 회원 탈퇴의 방법으로 정보 삭제 및 처리 정지를 요청할 수도 있습니다.
				<br />
				2. 이용자 혹은 만 14세 미만 아동의 법정대리인이 전 항의 권리행사를 위하여 개인정보 보호책임자에게 서면,
				전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
				<br />
				3. 전 항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
				있습니다. 단, 이 경우 개인정보 보호법 시행규칙에 따른 위임장을 제출하셔야 합니다.
				<br />
				4. 이용자의 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
				경우에는 그 삭제를 요구할 수 없습니다.
				<br />
				5. 회사는 이용자 권리에 따른 열람정정&middot;삭제 또는 처리정지의 요구 시 열람 등 요구를 한 자가
				본인이거나 정당한 대리인인지를 확인합니다.
				<br />
				6. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용
				또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게
				지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
				<br />
				7. 회사는 이용자 혹은 법정 대리인의 요청에 의해 삭제된 개인정보는 개인정보 파기절차 및 파기방법에 명시된
				바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
				<br />
				8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항
				<br />
				회사는 이용자의 접속 정보를 저장하고 수시로 불러오는 쿠키 및 그와 유사한 기술을 사용합니다. 쿠키는
				이용자가 어플리케이션 서비스를 이용하거나 사이트를 방문하였을 때 이용자의 단말기에 저장되는 소량의
				정보이며, 이용자가 사이트를 재방문했을 때 읽을 수 있는 정보를 저장합니다.
			</p>
			<p>
				1. 쿠키의 사용 목적
				<br />◦ 이용자의 접속 세션을 유지하기 위하여
				<br />◦ 이용자의 서비스 및 사이트에 대한 이용 및 방문 행태 분석을 위하여
				<br />◦ 보안 접속을 위하여
				<br />
				2. 쿠키의 설치/운영 및 거부
				<br />◦ 이용자가 웹을 통해 서비스를 이용하는 경우 브라우저의 옵션을 설정함으로써 모든 쿠키의 허용여부를
				결정할 수 있습니다.
				<br />◦ 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.
			</p>
			<p>&nbsp;</p>
			<p>
				<br />
				15. 개인정보 보호책임자
				<br />
				회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 요청은 개인정보 보호책임자 혹은 담당부서로
				신고하실 수 있습니다. 회사는 이용자의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
			</p>
			<p>
				<br />
				개인정보 보호책임자
				<br />- 성명 : 고소연
				<br />- 직급 : 대표
				<br />- 연락처 : 010-5314-8907
			</p>
			<p>
				16. 개인정보 처리방침의 적용 및 변경
				<br />이 개인정보 처리방침은 2022년 10월 1일부터 적용됩니다.
			</p>
		</Container>
	);
};
export default TermsOfService;
