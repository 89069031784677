import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import RoutesBase from './routes';

const App = () => {
	return (
		<Router>
			<RoutesBase />
		</Router>
	);
};

export default App;
