import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Offcanvas } from 'react-bootstrap';
import { AuthStore, useAuthStore } from '../../store/AuthStore';
import './NavigationBar.css';

const NavigationBar = () => {
	const navigate = useNavigate();
	const { isLoggedIn, grade } = useAuthStore();

	const [expanded, setExpanded] = useState<any>(false);

	const logout = () => {
		AuthStore.logout();
		alert('로그아웃 되었습니다.');
		navigate('/main');
	};

	return (
		<Navbar bg="light" expand={false} expanded={expanded} id="gajago-nav" onToggle={() => setExpanded(!expanded)}>
			<Navbar.Brand style={{ cursor: 'pointer' }} onClick={() => navigate('/main')}>
				GAJAGO
			</Navbar.Brand>
			<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} />
			<Navbar.Offcanvas
				id={`offcanvasNavbar-expand-${false}`}
				aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
				placement="end">
				<Offcanvas.Header closeButton>
					<Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`}>GAJAGO</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Nav className="justify-content-end flex-grow-1 pe-3">
						<Nav.Link
							onClick={() => {
								navigate('/main');
								setExpanded(false);
							}}>
							Home
						</Nav.Link>
						<Nav.Link
							onClick={() => {
								navigate('/review');
								setExpanded(false);
							}}>
							Review
						</Nav.Link>

						{isLoggedIn ? (
							<>
								<Nav.Link
									onClick={() => {
										navigate('/myPage');
										setExpanded(false);
									}}>
									MyPage
								</Nav.Link>
								<Nav.Link
									onClick={() => {
										logout();
										setExpanded(false);
									}}>
									Logout
								</Nav.Link>
							</>
						) : (
							<>
								<Nav.Link
									onClick={() => {
										navigate('/login');
										setExpanded(false);
									}}>
									Login
								</Nav.Link>
								<Nav.Link
									onClick={() => {
										navigate('/join');
										setExpanded(false);
									}}>
									Join
								</Nav.Link>
							</>
						)}
						<Nav.Link
							onClick={() => {
								if (isLoggedIn) {
									navigate('/inquiry');
									setExpanded(false);
								} else {
									alert('로그인 후 이용 가능합니다.');
								}
							}}>
							문의
						</Nav.Link>
						<Nav.Link
							onClick={() => {
								if (isLoggedIn) {
									navigate('/report');
									setExpanded(false);
								} else {
									alert('로그인 후 이용 가능합니다.');
								}
							}}>
							신고
						</Nav.Link>
						<Nav.Link
							onClick={() => {
								navigate('/termsOfService');
								setExpanded(false);
							}}>
							이용약관
						</Nav.Link>

						{isLoggedIn && grade === 'ADMIN' && (
							<div className="admin-wrap">
								<h5>Admin</h5>
								<Nav.Link
									onClick={() => {
										navigate('/admin/product/list');
										setExpanded(false);
									}}>
									파티
								</Nav.Link>
								<Nav.Link
									onClick={() => {
										navigate('/admin/inquiry/list');
										setExpanded(false);
									}}>
									문의
								</Nav.Link>
								<Nav.Link
									onClick={() => {
										navigate('/admin/report/list');
										setExpanded(false);
									}}>
									신고
								</Nav.Link>
							</div>
						)}
					</Nav>
				</Offcanvas.Body>
			</Navbar.Offcanvas>
		</Navbar>
	);
};

export default observer(NavigationBar);
