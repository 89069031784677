export const initialLogin = {
	accountId: '',
	password: '',
};

export const initialJoin = {
	accountId: '',
	// birth: new Date(),
	birth: '',
	email: '',
	gender: '',
	hobby: '',
	job: '',
	name: '',
	nickname: '',
	password: '',
	phoneNumber: '',
	knownPath: null,
	profileImage: '',
	height: null,
	instagramId: null,
};

export const initialJoinValidation = {
	isAccountId: null,
	isIdDuplicateCheck: null,
	isPassword: null,
	isPasswordConfirm: null,
	isName: null,
	isNickname: null,
	isBirth: null,
	isPhoneNumber: null,
	isPhoneNumberCheck: null,
	isJob: null,
	isHobby: null,
	isGender: null,
	isEmail: null,
	isEmailCheck: null,
	isProfileImage: null,
};
export const initialPartyFormInfo = {
	detailContentHtml: '',
	entryFee: { female: 0, male: 0 },
	mainImageUrl: '',
	category: '',
	place: { address: '', latitude: null, longitude: null },
	recruitment: { female: 0, male: 0, maleRecruited: 0, femaleRecruited: 0 },
	schedule: { endAt: '', startAt: '' },
	thumbnailUrl: '',
	title: '',
};

export const initialPagination = {
	currentPage: 1,
	totalPages: 0,
	size: 30,
	totalCount: 0,
};

export const initialReviewForm = {
	images: null,
	note: '',
	score: 0,
};

export const initialQuestionForm = {
	phoneNumber: '',
	questionContent: '',
	title: '',
	type: '',
};
