import React from 'react';
import './Container.css';

interface Props {
	children: React.ReactNode;
}

const AdminContainer: React.FC<Props> = (props) => {
	const { children } = props;
	return <div id="gajago-admin-container">{children}</div>;
};

export default AdminContainer;
