import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { observer } from 'mobx-react';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import { Figure, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Container from '../components/container/Container';
import Filter from '../Filter';
import { useAuthStore } from '../store/AuthStore';
import './css/Detail.css';
import { PartyStore, usePartyStore } from '../store/admin/PartyStore';
import ApplicationModal from './ApplicationModal';
import Popup from '../components/Popup/Popup';

const Detail = () => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openPopup, setOpenPopup] = useState<boolean>(false);

	const navigate = useNavigate();
	const { detailId } = useParams();
	const { isLoggedIn } = useAuthStore();
	const { partyForm } = usePartyStore();
	const { toDate, toTime, getDayOfWeek, numberComma, dateTimeLocal } = Filter();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (detailId) {
			PartyStore.setPartyId(parseInt(detailId, 10));
			PartyStore.setApplicationPartyId(parseInt(detailId, 10));
			PartyStore.getParty();
		}
	}, []);

	return (
		<Container>
			<Figure className="detail-figure w-100">
				<Figure.Image alt="poster" src={partyForm.mainImageUrl} className="w-100" />
			</Figure>
			<p className="detail-title">{partyForm.title}</p>
			<div className="section info-section-1">
				<div className="info-wrap">
					<p className="info-title">진행 일자</p>
					<p className="info-desc">
						{toDate(partyForm.schedule.startAt)} {getDayOfWeek(toDate(partyForm.schedule.startAt))}{' '}
						{toTime(partyForm.schedule.startAt)}{' '}
						{parseInt(toTime(partyForm.schedule.startAt)?.split(':')[0], 10) > 12 ? 'PM' : 'AM'}
					</p>
				</div>
				<div className="info-wrap">
					<p className="info-title">진행 장소</p>
					<p className="info-desc">{partyForm.place.address}</p>
				</div>
				<div className="info-wrap">
					<p className="info-title">참가비</p>
					<p className="info-desc">
						남성 - {numberComma(partyForm.entryFee.male)}원
						<br /> 여성 - {numberComma(partyForm.entryFee.female)}원
					</p>
				</div>
				<div className="info-wrap">
					<p className="info-title">참가 인원</p>

					<p className="info-desc">
						<div className="d-flex">
							<div className="d-flex">
								<p>🙋‍♂️</p>
								<p className="ms-2 me-4">
									{partyForm.recruitment.maleRecruited} / {partyForm.recruitment.male}
								</p>
							</div>

							<div className="d-flex">
								<p>🙋‍♀️</p>
								<p className="ms-2">
									{partyForm.recruitment.femaleRecruited} / {partyForm.recruitment.female}
								</p>
							</div>
						</div>
					</p>
				</div>
			</div>
			<div className="section">{parse(partyForm.detailContentHtml)}</div>

			{isLoggedIn ? (
				<Button variant="dark" className="w-100" onClick={() => setOpenModal(true)}>
					신청하기
				</Button>
			) : (
				<Button variant="dark" className="w-100" onClick={() => setOpenPopup(true)}>
					신청하기
				</Button>
			)}

			<ApplicationModal show={openModal} onHide={() => setOpenModal(false)} />

			<Popup header="" show={openPopup}>
				<p>로그인 후 신청이 가능합니다.</p>

				<Button className="me-3" variant="outline-dark" onClick={() => setOpenPopup(false)}>
					닫기
				</Button>
				<Button variant="dark" onClick={() => navigate('/login')}>
					확인
				</Button>
			</Popup>
		</Container>
	);
};

export default observer(Detail);
