import { observer } from 'mobx-react';
import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Popup from '../components/Popup/Popup';
import { PartyStore, usePartyStore } from '../store/admin/PartyStore';

interface Props {
	show: boolean;
	onHide: () => void;
}

const ApplicationModal: React.FC<Props> = (props: Props) => {
	const { show, onHide } = props;
	const { aplicationForm, successPopup } = usePartyStore();

	return (
		<Modal show={show} onHide={onHide} centered>
			<Modal.Header closeButton>
				<Modal.Title>신청서</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Label>이 모임에 바라는 점 혹은 가자고에게 하고싶은 말을 적어주세요!</Form.Label>
					<Form.Control
						as="textarea"
						rows={5}
						value={aplicationForm.note || ''}
						onChange={(event) => PartyStore.setApplicationNote(event.target.value)}
					/>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button
					style={{ width: '100%', height: '40px' }}
					variant="dark"
					onClick={() => PartyStore.submitApplication()}>
					신청하기
				</Button>
			</Modal.Footer>

			<Popup header="신청 완료" show={successPopup}>
				<p>
					국민 01250104287423
					<br />* 입금까지 완료되어야 참가 확정입니다.
				</p>

				<Button
					variant="dark"
					onClick={() => {
						PartyStore.openPopup(false);
						onHide();
					}}>
					확인
				</Button>
			</Popup>
		</Modal>
	);
};

export default observer(ApplicationModal);
