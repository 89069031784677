import { createContext, useContext } from 'react';
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { initialLogin } from './constants';
import { LoginInterface } from '../types/types';
import AuthRepository from '../repositories/AuthRepository';
import { Nullable } from '../types/base';

class AuthStoreImple {
	isLoggedIn = false;
	loginData: LoginInterface = initialLogin;
	grade = '';
	userId: Nullable<number> = null;

	authRepository = AuthRepository();

	constructor() {
		makeAutoObservable(this);
	}

	cleanUp() {
		runInAction(() => {
			this.loginData = initialLogin;
		});
	}

	setLogin(key: string, value: string) {
		runInAction(() => {
			this.loginData[key] = value;
		});
	}

	async login() {
		const { data } = await this.authRepository.login(this.loginData);

		if (data.id) {
			return Promise.reject();
		}

		localStorage.setItem('accessToken', data.data.accessToken);
		this.isLoggedIn = true;
		this.grade = data.data.grade;
		this.userId = data.data.id;

		return Promise.resolve();
	}

	async logout() {
		localStorage.removeItem('accessToken');
		this.cleanUp();

		runInAction(() => {
			this.isLoggedIn = false;
		});
	}
}

export const AuthStore = new AuthStoreImple();
const AuthContext = createContext(AuthStore);
export const useAuthStore = (): AuthStoreImple => useContext(AuthContext);
