/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosResponse, AxiosError, AxiosRequestHeaders } from 'axios';
// import { AuthVm } from '../stores/Auth/AuthViewModel';
import { DataRepositoryInterface } from '../types/base';
import { BASE_URL } from './config';

export default function DataRepository(): Readonly<DataRepositoryInterface> {
	const { host } = window.location;
	// if (host !== 'partner.inflca.com') {
	// 	BASE_URL = STAGE_BASE_URL;
	// }
	let fetchFailed = 0;
	const resetFetchFailed = () => {
		fetchFailed = 0;
	};
	const makeHeader = (useAuthorizationHeader = true) => {
		let headers = { 'Content-Type': 'application/json' };
		const accessToken = localStorage.getItem('accessToken');
		if (useAuthorizationHeader && accessToken) {
			const authorization = {
				Authorization: `Bearer ${accessToken}`,
			};
			headers = Object.assign(headers, authorization);
		}
		return headers;
	};
	const refreshTokenAndRetry = async <F extends Function, Parameters>(retry: F, args: Parameters) => {
		try {
			// await AuthVm.refreshJwtToken();
			const response = await retry.apply(retry, args);
			return response;
		} catch (error) {
			resetFetchFailed();
			// AuthVm.logout();
			throw error;
		}
	};

	const handle401ElseThrowError = <F extends Function, Parameters>(
		status: number,
		error: AxiosError,
		retry: F,
		args: Parameters
	) => {
		if (status === 401) {
			return refreshTokenAndRetry(retry, args);
		}
		throw error;
	};

	const handleAxiosError = <F extends Function, Parameters>(error: AxiosError, retry: F, args: Parameters) => {
		const { response } = error;
		if (response) {
			const { status } = response;
			return handle401ElseThrowError(status, error, retry, args);
		}
		throw error;
	};

	const get = async (endPoint: string, query = ''): Promise<AxiosResponse> => {
		try {
			const headers = makeHeader();
			const response = await axios.get(`${BASE_URL}${endPoint}${query}`, { headers });
			resetFetchFailed();
			return response;
		} catch (error) {
			fetchFailed += 1;
			if (axios.isAxiosError(error) && fetchFailed < 2) {
				return handleAxiosError(error, get, [endPoint, query]);
			}
			throw error;
		}
	};

	const post = async <Parameters>(
		endPoint: string,
		param?: Parameters,
		useAuthorizationHeader = true
	): Promise<AxiosResponse> => {
		try {
			const headers = makeHeader(useAuthorizationHeader);
			const response = await axios.post(`${BASE_URL}${endPoint}`, param, { headers });
			resetFetchFailed();
			return response;
		} catch (error) {
			fetchFailed += 1;
			if (axios.isAxiosError(error) && fetchFailed < 2) {
				return handleAxiosError(error, post, [endPoint, param, useAuthorizationHeader]);
			}
			throw error;
		}
	};

	const deleteOne = async (endPoint: string): Promise<AxiosResponse> => {
		try {
			const headers = makeHeader();
			const response = await axios.delete(`${BASE_URL}${endPoint}`, { headers });
			resetFetchFailed();
			return response;
		} catch (error) {
			fetchFailed += 1;
			if (axios.isAxiosError(error) && fetchFailed < 2) {
				return handleAxiosError(error, deleteOne, [endPoint]);
			}
			throw error;
		}
	};

	const deleteMany = async <Parameters>(endPoint: string, param: Parameters): Promise<AxiosResponse> => {
		try {
			const headers = makeHeader();
			const response = await axios.delete(`${BASE_URL}${endPoint}`, { data: param, headers });
			resetFetchFailed();
			return response;
		} catch (error) {
			fetchFailed += 1;
			if (axios.isAxiosError(error) && fetchFailed < 2) {
				return handleAxiosError(error, deleteMany, [endPoint, param]);
			}
			throw error;
		}
	};

	const put = async <Parameters, Headers extends AxiosRequestHeaders>(
		endPoint: string,
		param: Parameters,
		headers: Headers
	): Promise<AxiosResponse> => {
		return axios.put(`${BASE_URL}${endPoint}`, param, { headers });
	};

	const patch = async <Parameters, Headers extends AxiosRequestHeaders>(
		endPoint: string,
		param: Parameters,
		headers: Headers
	): Promise<AxiosResponse> => {
		return axios.patch(`${BASE_URL}${endPoint}`, param, { headers });
	};

	const update = async <Parameters>(endPoint: string, param: Parameters, method = 'PUT'): Promise<AxiosResponse> => {
		try {
			const headers = makeHeader();
			const response =
				method === 'PUT' ? await put(endPoint, param, headers) : await patch(endPoint, param, headers);
			resetFetchFailed();
			return response;
		} catch (error) {
			fetchFailed += 1;
			if (axios.isAxiosError(error) && fetchFailed < 2) {
				return handleAxiosError(error, update, [endPoint, param, method]);
			}
			throw error;
		}
	};

	return Object.freeze({
		get,
		post,
		deleteOne,
		deleteMany,
		patch,
		update,
	});
}
