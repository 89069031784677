import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Button, Form, Figure } from 'react-bootstrap';
import AdminContainer from '../../components/container/AdminContainer';
import FormField from '../../components/Form/FormField';
import FormRow from '../../components/Form/FormRow';
import TinyMCE from '../../components/TinyMCE/TinyMCE';
import AttachmentRepository from '../../repositories/admin/AttachmentRepository';
import { PartyStore, usePartyStore } from '../../store/admin/PartyStore';
import '../css/Admin.css';

const ProductDetail = () => {
	const { uploadFile } = AttachmentRepository();
	const { partyForm, partyId } = usePartyStore();
	// const { productId } = useParams();
	// const navigate = useNavigate();

	const handleDisplayFileDetails = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		if (event.target.files) {
			const file = event.target.files[0];

			try {
				const { data } = await uploadFile(file);
				PartyStore.setMainImageUrl(data.data);
			} catch (error) {
				alert('업로드에 실패하였습니다.');
			}
		}
	};

	const handleThumbnailFileDetails = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		if (event.target.files) {
			const file = event.target.files[0];

			try {
				const { data } = await uploadFile(file);
				PartyStore.setThumbnailUrl(data.data);
			} catch (error) {
				alert('업로드에 실패하였습니다.');
			}
		}
	};

	const save = async () => {
		try {
			const data = await PartyStore.saveParty();
			// navigate(`/admin/product/${data}`);
			alert('생성에 성공하였습니다.');
		} catch (error) {
			alert('생성에 실패하였습니다.');
		}
	};

	const update = async () => {
		try {
			await PartyStore.updateParty();
			alert('수정에 성공하였습니다.');
		} catch (error) {
			alert('수정에 실패하였습니다.');
		}
	};

	useEffect(() => {
		// PartyStore.setPartyId(parseInt(productId, 10));
		PartyStore.getParty();

		return () => {
			PartyStore.cleanUp();
		};
	}, []);

	return (
		<AdminContainer>
			<div className="detail-info-wrap">
				<h2>상세정보</h2>
				<div className="detail-info-form-wrap">
					<Form>
						<FormRow>
							<FormField label="제목" className="basic-form label-form">
								<Form.Group>
									<Form.Control
										type="text"
										value={partyForm.title || ''}
										onChange={(event) => PartyStore.setTitle(event.target.value)}
									/>
								</Form.Group>
							</FormField>
						</FormRow>
						<FormRow>
							<FormField label="대표 이미지" className="basic-form label-form">
								<Form.Group>
									<Button variant="dark">
										<label htmlFor="file-upload1" style={{ cursor: 'pointer' }}>
											파일 등록
										</label>
										<input
											type="file"
											id="file-upload1"
											className="d-none"
											accept=".jpg, .png, .jpeg, pdf"
											onChange={(event) => handleDisplayFileDetails(event)}
										/>
									</Button>
									<div className="mt-3">
										{partyForm.mainImageUrl && (
											<Figure>
												<Figure.Image width={200} alt="img" src={partyForm.mainImageUrl} />
											</Figure>
										)}
									</div>
								</Form.Group>
							</FormField>
						</FormRow>
						<FormRow>
							<FormField label="썸네일" className="basic-form label-form">
								<Form.Group>
									<Button variant="dark">
										<label htmlFor="file-upload2" style={{ cursor: 'pointer' }}>
											파일 등록
										</label>
										<input
											type="file"
											id="file-upload2"
											className="d-none"
											accept=".jpg, .png, .jpeg, pdf"
											onChange={(event) => handleThumbnailFileDetails(event)}
										/>
									</Button>
									<div className="mt-3">
										{partyForm.thumbnailUrl && (
											<Figure>
												<Figure.Image width={200} alt="img" src={partyForm.thumbnailUrl} />
											</Figure>
										)}
									</div>
								</Form.Group>
							</FormField>
						</FormRow>
						<FormRow>
							<FormField label="카테고리" className="basic-form label-form">
								<Form.Select
									onChange={(event) => PartyStore.setCategory(event.target.value)}
									value={partyForm.category || ''}>
									<option>카테고리를 선택해주세요.</option>
									<option value="PARTY">파티</option>
									<option value="WINE">와인모임</option>
									<option value="TRIP">여행</option>
									<option value="CLASS">클래스</option>
								</Form.Select>
							</FormField>
						</FormRow>
						<FormRow>
							<FormField label="장소" className="basic-form label-form">
								<Form.Group>
									<Form.Control
										type="text"
										value={partyForm.place.address || ''}
										onChange={(event) => PartyStore.setAddress(event.target.value)}
									/>
								</Form.Group>
							</FormField>
						</FormRow>
						<FormRow>
							<FormField label="금액" className="basic-form label-form">
								<Form.Group className="mb-3">
									<Form.Label>남성</Form.Label>
									<Form.Control
										type="number"
										value={partyForm.entryFee.male || ''}
										onChange={(event) =>
											PartyStore.setMaleEntryFee(parseInt(event.target.value, 10))
										}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>여성</Form.Label>
									<Form.Control
										type="number"
										value={partyForm.entryFee.female || ''}
										onChange={(event) =>
											PartyStore.setFemaleEntryFee(parseInt(event.target.value, 10))
										}
									/>
								</Form.Group>
							</FormField>
						</FormRow>
						<FormRow>
							<FormField label="인원" className="basic-form label-form">
								<Form.Group className="mb-3">
									<Form.Label>남성</Form.Label>
									<Form.Control
										type="number"
										value={partyForm.recruitment.male || ''}
										onChange={(event) =>
											PartyStore.setRecruitmentMale(parseInt(event.target.value, 10))
										}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>여성</Form.Label>
									<Form.Control
										type="number"
										value={partyForm.recruitment.female || ''}
										onChange={(event) =>
											PartyStore.setRecruitmentFemale(parseInt(event.target.value, 10))
										}
									/>
								</Form.Group>
							</FormField>
						</FormRow>
						<FormRow>
							<FormField label="날짜" className="basic-form label-form">
								<Form.Group className="mb-3">
									<Form.Label>시작일</Form.Label>
									<Form.Control
										type="datetime-local"
										value={partyForm.schedule.startAt || ''}
										onChange={(event) => PartyStore.setStartAt(event.target.value)}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>종료일</Form.Label>
									<Form.Control
										type="datetime-local"
										value={partyForm.schedule.endAt || ''}
										onChange={(event) => PartyStore.setEndAt(event.target.value)}
									/>
								</Form.Group>
							</FormField>
						</FormRow>
					</Form>
				</div>
				<h2>콘텐츠</h2>
				<TinyMCE
					onChange={(event) => PartyStore.setDetailContent(event)}
					value={partyForm.detailContentHtml || ''}
				/>

				<Button variant="dark" className="save-btn" onClick={() => (partyId ? update() : save())}>
					저장
				</Button>
			</div>
		</AdminContainer>
	);
};

export default observer(ProductDetail);
