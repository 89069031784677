import { createContext, useContext } from 'react';
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { JoinInterface, JoinValidationInterface } from '../../types/types';
import { initialJoin, initialJoinValidation } from '../constants';
import { Nullable } from '../../types/base';
import JoinRepository from '../../repositories/join/JoinRepository';
import Filter from '../../Filter';
import AttachmentRepository from '../../repositories/admin/AttachmentRepository';

class JoinStoreImpl {
	join: JoinInterface = initialJoin;
	passwordConfirm = '';
	joinValidation: JoinValidationInterface = initialJoinValidation;
	isJoinData = false;

	joinRepository = JoinRepository();
	attachmentRepository = AttachmentRepository();
	filter = Filter();

	constructor() {
		makeAutoObservable(this);
	}

	cleanUp() {
		runInAction(() => {
			this.join = initialJoin;
			this.joinValidation = initialJoinValidation;
			this.passwordConfirm = '';
		});
	}

	setPasswordConfirm(password: string) {
		runInAction(() => {
			this.passwordConfirm = password;
		});
	}

	setJoin(key: string, value: string | any) {
		runInAction(() => {
			this.join[key] = value;

			if (key === 'phoneNumber') {
				this.join.phoneNumber = this.filter.transformToPhonNumber(this.join.phoneNumber);
			}
		});
	}

	setJoinValidation(key: string, value: Nullable<boolean>) {
		runInAction(() => {
			this.joinValidation[key] = value;
		});
	}

	validateId() {
		runInAction(() => {
			const { accountId } = this.join;

			if (!accountId) {
				this.setJoinValidation('isAccountId', false);
			} else {
				const regEx = /^[a-z0-9_-]*$/;
				if (accountId.length < 5) {
					this.setJoinValidation('isAccountId', false);
				} else if (!regEx.test(accountId)) {
					this.setJoinValidation('isAccountId', false);
				} else {
					this.setJoinValidation('isAccountId', true);
				}
			}
		});
	}

	isAllValidateFieldsValid(): boolean {
		const temp = Object.keys(this.joinValidation).every((key) => {
			const status = this.joinValidation[key];
			return status !== null && status;
		});
		return temp;
	}

	static checkPassword = (password: string) => {
		let charType = 0;
		if (/[a-z]/.test(password)) {
			charType += 1;
		}
		if (/[A-Z]/.test(password)) {
			charType += 1;
		}
		if (/[0-9]/.test(password)) {
			charType += 1;
		}
		// eslint-disable-next-line no-useless-escape
		if (/[`~!@#$%^&*|\\\‘\“;:\/?]/gi.test(password)) charType += 1;
		return !(password.length < 8 || password.length > 16 || charType < 3);
	};

	validatePassword() {
		runInAction(() => {
			const { password } = this.join;
			const isValidatePassword = JoinStoreImpl.checkPassword(password);

			this.setJoinValidation('isPassword', isValidatePassword);
		});
	}

	validatePasswordConfirm() {
		runInAction(() => {
			const { password } = this.join;

			this.setJoinValidation('isPasswordConfirm', password === this.passwordConfirm);
		});
	}

	validateName() {
		runInAction(() => {
			const { name } = this.join;

			if (!name) {
				this.setJoinValidation('isName', false);
			} else {
				this.setJoinValidation('isName', true);
			}
		});
	}

	validateNickname() {
		runInAction(() => {
			const { nickname } = this.join;

			if (!nickname) {
				this.setJoinValidation('isNickname', false);
			} else {
				this.setJoinValidation('isNickname', true);
			}
		});
	}

	validateBirth() {
		runInAction(() => {
			const { birth } = this.join;

			if (!birth) {
				this.setJoinValidation('isBirth', false);
			} else {
				this.setJoinValidation('isBirth', true);
			}
		});
	}

	validatePhoneNumber() {
		runInAction(() => {
			const { phoneNumber } = this.join;
			const regEx = /\d{3}-\d{4}-\d{4}/;

			if (!phoneNumber) {
				this.setJoinValidation('isPhoneNumber', false);
			} else {
				this.setJoinValidation('isPhoneNumber', true);
				if (phoneNumber.length < 13 && !regEx.test(phoneNumber)) {
					this.setJoinValidation('isPhoneNumberCheck', false);
				} else {
					this.setJoinValidation('isPhoneNumberCheck', true);
				}
			}
		});
	}

	validateJob() {
		runInAction(() => {
			const { job } = this.join;

			if (!job) {
				this.setJoinValidation('isJob', false);
			} else {
				this.setJoinValidation('isJob', true);
			}
		});
	}

	validateHobby() {
		runInAction(() => {
			const { hobby } = this.join;

			if (!hobby) {
				this.setJoinValidation('isHobby', false);
			} else {
				this.setJoinValidation('isHobby', true);
			}
		});
	}

	validateGender() {
		runInAction(() => {
			const { gender } = this.join;

			if (!gender) {
				this.setJoinValidation('isGender', false);
			} else {
				this.setJoinValidation('isGender', true);
			}
		});
	}

	validateEmail() {
		runInAction(() => {
			const { email } = this.join;
			const regEx =
				// eslint-disable-next-line no-useless-escape
				/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;

			if (!email) {
				this.setJoinValidation('isEmail', false);
			} else {
				this.setJoinValidation('isEmail', true);

				if (!regEx.test(email)) {
					this.setJoinValidation('isEmailCheck', false);
				} else {
					this.setJoinValidation('isEmailCheck', true);
				}
			}
		});
	}

	validateProfileImage() {
		runInAction(() => {
			const { profileImage } = this.join;

			if (!profileImage) {
				this.setJoinValidation('isProfileImage', false);
			} else {
				this.setJoinValidation('isProfileImage', true);
			}
		});
	}

	async signUp(): Promise<void> {
		try {
			const temp = {
				...this.join,
				birth: moment(this.join.birth).format().substring(0, 10).concat('T00:00:00.000Z'),
			};
			const { data } = await this.joinRepository.signUp(temp);
			if (data.status === 'SUCCESS') {
				alert('회원가입에 성공하였습니다.');
			}
		} catch (error) {
			console.log('회원가입에 실패하였습니다.');
			console.log(error);
		}
	}

	async idDuplicateCheck(): Promise<void> {
		try {
			const { data } = await this.joinRepository.idDuplicateCheck(this.join.accountId);
			const { status } = data;
			if (status === 'SUCCESS') {
				this.setJoinValidation('isIdDuplicateCheck', true);
			} else {
				this.setJoinValidation('isIdDuplicateCheck', false);
			}
		} catch (error) {
			console.log('아이디 중복확인에 실패하였습니다.');
		}
	}

	setJoinInfo(info: JoinInterface) {
		runInAction(() => {
			this.join = info;
		});
	}

	isMyPageValidateFieldsValid(): boolean {
		if (!this.joinValidation.isName) {
			return false;
		}
		if (!this.joinValidation.isNickname) {
			return false;
		}
		if (!this.joinValidation.isBirth) {
			return false;
		}
		if (!this.joinValidation.isPhoneNumber) {
			return false;
		}
		if (!this.joinValidation.isPhoneNumberCheck) {
			return false;
		}
		if (!this.joinValidation.isJob) {
			return false;
		}
		if (!this.joinValidation.isHobby) {
			return false;
		}
		if (!this.joinValidation.isGender) {
			return false;
		}
		if (!this.joinValidation.isEmail) {
			return false;
		}
		if (!this.joinValidation.isEmailCheck) {
			return false;
		}

		return true;
	}

	setMypageValidate() {
		runInAction(() => {
			this.validateName();
			this.validateNickname();
			this.validateBirth();
			this.validatePhoneNumber();
			this.validateJob();
			this.validateHobby();
			this.validateGender();
			this.validateEmail();
		});
	}
}

export const JoinStore = new JoinStoreImpl();
const JoinContext = createContext(JoinStore);
export const useJoinSotre = (): JoinStoreImpl => useContext(JoinContext);

reaction(
	() => JoinStore.join.accountId,
	async () => {
		await JoinStore.idDuplicateCheck();
	}
);
