import React from 'react';
import { Col } from 'react-bootstrap';
import FormLabel from './FormLabel';

interface Props {
	label: string | React.ReactNode;
	children: React.ReactNode;
	className?: string;
}

const FormField: React.FC<Props> = (props) => {
	const { label, children, className } = props;

	return (
		<>
			<FormLabel label={label} />
			<Col className={className} style={{ paddingTop: '1.25rem' }}>
				{children}
			</Col>
		</>
	);
};
FormField.defaultProps = {
	className: 'basic-form',
};

export default FormField;
