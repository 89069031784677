import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Form } from 'react-bootstrap';
import AdminContainer from '../../components/container/AdminContainer';
import ListRenderer from '../../components/listRenderer/ListRenderer';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import Filter from '../../Filter';
import QuestionRepository from '../../repositories/admin/QuestionRepository';
import { QuestionListInterface } from '../../types/types';
import '../css/Admin.css';
import { QuestionStore } from '../../store/admin/QuestionStore';

const InquiryList = () => {
	const [size, setSize] = useState<number>(30);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalCnt, setTotalCnt] = useState<number>(0);
	const [sort, setSort] = useState<string>('DESC');
	const [questionList, setQuestionList] = useState<Array<QuestionListInterface>>([]);

	const navigate = useNavigate();
	const { toDate, toTime, dateTimeLocal } = Filter();
	const { getQuestions } = QuestionRepository();

	const fetchAndHandleData = async (): Promise<void> => {
		try {
			const query = `?direction=${sort}&page=${currentPage}&size=${size}&type=INQUIRY`;
			const { data } = await getQuestions(query);
			const { totalCount } = data;

			setQuestionList(data.data.data);
			setTotalCnt(totalCount);
			setTotalPages(Math.ceil(totalCount / size));
		} catch (error) {
			alert('error');
		}
	};
	const questionType = (type: string) => {
		switch (type) {
			case 'ONE_ON_ONE':
				return '일대일 문의';
			case 'CUSTOMER_SERVICE':
				return '고객 센터';
			case 'PARTNERSHIP':
				return '제휴사';
			default:
				return '';
		}
	};

	useEffect(() => {
		fetchAndHandleData();
	}, []);

	useEffect(() => {
		fetchAndHandleData();
	}, [sort, currentPage, size]);

	return (
		<AdminContainer>
			<div>
				<div className="product-list-btn-wrap justify-content-end mb-4">
					<div>
						<Form.Select
							value={sort}
							onChange={(event) => {
								setSort(event.target.value);
							}}>
							<option value="ASC">오름차순</option>
							<option value="DESC">내림차순</option>
						</Form.Select>
						<Form.Select value={size} onChange={(event) => setSize(parseInt(event.target.value, 10))}>
							<option value={30}>30</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
						</Form.Select>
					</div>
				</div>
				<Table striped bordered hover className="product-list-table">
					<thead>
						<tr>
							<th style={{ width: '5%' }}>글 번호</th>
							<th style={{ width: '30%' }}>문의 유형</th>
							<th style={{ width: '25%' }}>작성자 닉네임</th>
							<th style={{ width: '25%' }}>작성일</th>
							<th style={{ width: '10%' }}>답변 여부</th>
							<th style={{ width: '5%' }}>상세</th>
						</tr>
					</thead>
					<tbody>
						<ListRenderer
							data={questionList}
							renderItems={(item) => (
								<tr>
									<td>
										<span>{item.questionId}</span>
									</td>
									<td>{questionType(item.type)}</td>
									<td>{item.nickname}</td>
									<td>
										{toDate(dateTimeLocal(item.createdAt))} {toTime(dateTimeLocal(item.createdAt))}
									</td>
									<td>{item.isAnswered === true ? '답변 완료' : '미답변'}</td>
									<td>
										<Button
											variant="dark"
											className="w-100"
											onClick={() => {
												navigate(`/admin/inquiry/${item.questionId}`);
												QuestionStore.setQuestionId(item.questionId);
											}}>
											상세
										</Button>
									</td>
								</tr>
							)}
						/>
					</tbody>
				</Table>
			</div>
			<CustomPagination pageCount={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
		</AdminContainer>
	);
};

export default observer(InquiryList);
