import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NavigationBar from './gajago/components/navigation/NavigationBar';
import { useAuthStore } from './gajago/store/AuthStore';
import InquiryDetail from './gajago/views/admin/InquiryDetail';
import InquiryList from './gajago/views/admin/InquiryList';
import ProductDetail from './gajago/views/admin/ProductDetail';
import ProductList from './gajago/views/admin/ProductList';
import ReportDetail from './gajago/views/admin/ReportDetail';
import ReportList from './gajago/views/admin/ReportList';
import Detail from './gajago/views/Detail';
import FindId from './gajago/views/FindId';
import FindPassword from './gajago/views/FindPassword';
import Inquiry from './gajago/views/Inquiry';
import Join from './gajago/views/Join';
import Login from './gajago/views/Login';
import Main from './gajago/views/Main';
import MyPage from './gajago/views/MyPage';
import Report from './gajago/views/Report';
import Review from './gajago/views/Review';
import TermsOfService from './gajago/views/TermsOfService';

const publicRoutes = [
	{ path: '/login', component: () => <Login /> },
	{ path: '/login/findId', component: () => <FindId /> },
	{ path: '/login/findPassword', component: () => <FindPassword /> },
	{ path: '/join', component: () => <Join /> },
	{ path: '/main', component: () => <Main /> },
	{ path: '/review', component: () => <Review /> },
	{ path: '/detail/:detailId', component: () => <Detail /> },
	{ path: '/termsOfService', component: () => <TermsOfService /> },
	{ path: '/myPage', component: () => <MyPage /> },

	{ path: '/inquiry', component: () => <Inquiry /> },
	{ path: '/report', component: () => <Report /> },

	{ path: '/admin/product/list', component: () => <ProductList /> },
	{ path: '/admin/product/newProduct', component: () => <ProductDetail /> },
	{ path: '/admin/product/:productId', component: () => <ProductDetail /> },

	{ path: '/admin/inquiry/list', component: () => <InquiryList /> },
	{ path: '/admin/inquiry/:questionId', component: () => <InquiryDetail /> },

	{ path: '/admin/report/list', component: () => <ReportList /> },
	{ path: '/admin/report/:questionId', component: () => <ReportDetail /> },
];
const RoutesBase = () => {
	const { isLoggedIn } = useAuthStore();

	return (
		<div>
			<NavigationBar />
			<Routes>
				<Route path="*" element={<Main />} />
				<Route path="/" element={<Main />} />
				{publicRoutes.map((route) => (
					<Route path={route.path} key={route.path} element={<route.component />} />
				))}
			</Routes>
		</div>
	);
};

export default RoutesBase;
