/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

interface Props {
	data: Array<any>;
	renderItems: (item: any, index: number) => void;
}

const ListRenderer: React.FC<Props> = (props) => {
	const { data, renderItems } = props;
	return (
		<>
			{data.map((item, index) => {
				return renderItems(item, index);
			})}
		</>
	);
};

export default ListRenderer;
