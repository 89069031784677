import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Figure } from 'react-bootstrap';
import Container from '../components/container/Container';
import Popup from '../components/Popup/Popup';
import AttachmentRepository from '../repositories/admin/AttachmentRepository';
import AuthRepository from '../repositories/AuthRepository';
import { JoinStore, useJoinSotre } from '../store/join/JoinStore';
import { AuthStore } from '../store/AuthStore';

import './css/Join.css';

const MyPage = () => {
	const { uploadFile } = AttachmentRepository();
	const { getUserInfo, updateUserInfo, deactivate } = AuthRepository();
	const { join, joinValidation } = useJoinSotre();
	const [openPopup, setOpenPopup] = useState<boolean>(false);
	const navigate = useNavigate();

	const {
		isName,
		isNickname,
		isBirth,
		isPhoneNumber,
		isPhoneNumberCheck,
		isJob,
		isHobby,
		isGender,
		isEmail,
		isEmailCheck,
	} = joinValidation;

	const handleThumbnailFileDetails = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		if (event.target.files) {
			const file = event.target.files[0];

			try {
				const { data } = await uploadFile(file);
				JoinStore.setJoin('profileImage', data.data);
			} catch (error) {
				alert('업로드에 실패하였습니다.');
			}
		}
	};

	const getInfo = async () => {
		const { data } = await getUserInfo();
		const temp = { ...data.data, birth: data.data.birth.substring(0, data.data.birth.length - 10) };
		JoinStore.setJoinInfo(temp);
		JoinStore.setMypageValidate();
	};

	const updateInfo = async () => {
		try {
			const { accountId, password, ...temp } = join;
			await updateUserInfo({ ...temp, birth: temp.birth.concat('T00:00:00.000Z') });
			alert('회원 정보를 수정하였습니다.');
		} catch (error) {
			alert('회원 정보 수정에 실패하였습니다.');
		}
	};

	const userDeactivate = async () => {
		try {
			await deactivate();
			setOpenPopup(false);
			AuthStore.logout();
			navigate('/main');
		} catch (error) {
			alert('일시적인 오류가 발생하였습니다. 다시 시도해주세요.');
		}
	};

	useEffect(() => {
		getInfo();

		return () => {
			JoinStore.cleanUp();
		};
	}, []);

	return (
		<Container>
			<div className="d-flex justify-content-between align-items-end mb-4">
				<h2 className="title" style={{ margin: '0 0 60px 0' }}>
					MyPage
				</h2>
				<div>
					<span style={{ color: 'red' }}>*</span> 필수 정보
				</div>
			</div>

			<Form className="join-form-wrap">
				<Form.Group>
					<Form.Label>
						프로필 이미지 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<div className="profile-wrap">
						<div>
							{join.profileImage ? (
								<Figure>
									<Figure.Image width={180} alt="img" src={join.profileImage} />
								</Figure>
							) : (
								<div className="empty-img" />
							)}
						</div>
						<Button variant="dark">
							<label htmlFor="file-upload2" style={{ cursor: 'pointer' }}>
								파일 등록
							</label>
							<input
								type="file"
								id="file-upload2"
								className="d-none"
								accept=".jpg, .png, .jpeg, pdf"
								onChange={(event) => handleThumbnailFileDetails(event)}
							/>
						</Button>
					</div>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						이름 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="이름을 입력해 주세요."
						isInvalid={isName !== null && !isName}
						value={join.name}
						onBlur={() => JoinStore.validateName()}
						onChange={(event) => {
							JoinStore.setJoin('name', event.target.value);
							JoinStore.validateName();
						}}
					/>
					{isName !== null && !isName && (
						<Form.Control.Feedback type="invalid">이름을 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						닉네임 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="닉네임을 입력해 주세요."
						isInvalid={isNickname !== null && !isNickname}
						value={join.nickname}
						onBlur={() => JoinStore.validateNickname()}
						onChange={(event) => {
							JoinStore.setJoin('nickname', event.target.value);
							JoinStore.validateNickname();
						}}
					/>
					{isNickname !== null && !isNickname && (
						<Form.Control.Feedback type="invalid">닉네임을 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						생년월일 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="date"
						isInvalid={isBirth !== null && !isBirth}
						value={join.birth}
						onBlur={() => JoinStore.validateBirth()}
						onChange={(event) => {
							JoinStore.setJoin('birth', event.target.value);
							JoinStore.validateBirth();
						}}
					/>
					{isBirth !== null && !isBirth && (
						<Form.Control.Feedback type="invalid">생년월일을 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						핸드폰 번호 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="핸드폰 번호를 입력해 주세요."
						minLength={13}
						maxLength={13}
						isInvalid={
							(isPhoneNumber !== null && !isPhoneNumber) ||
							(isPhoneNumberCheck !== null && !isPhoneNumberCheck)
						}
						value={join.phoneNumber}
						onBlur={() => JoinStore.validatePhoneNumber()}
						onChange={(event) => {
							JoinStore.setJoin('phoneNumber', event.target.value);
							JoinStore.validatePhoneNumber();
						}}
					/>
					{isPhoneNumber !== null && !isPhoneNumber && (
						<Form.Control.Feedback type="invalid">휴대폰 번호를 입력해 주세요.</Form.Control.Feedback>
					)}
					{isPhoneNumber !== null && isPhoneNumber && isPhoneNumberCheck !== null && !isPhoneNumberCheck && (
						<Form.Control.Feedback type="invalid">
							휴대폰 번호를 정확하게 입력해 주세요.
						</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						직업 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="직업을 입력해 주세요."
						isInvalid={isJob !== null && !isJob}
						value={join.job}
						onBlur={() => JoinStore.validateJob()}
						onChange={(event) => {
							JoinStore.setJoin('job', event.target.value);
							JoinStore.validateJob();
						}}
					/>
					{isJob !== null && !isJob && (
						<Form.Control.Feedback type="invalid">직업을 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						취미 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="취미를 입력해 주세요.      ex) 여행, 서핑, 등산, 공예 등"
						isInvalid={isHobby !== null && !isHobby}
						value={join.hobby}
						onBlur={() => JoinStore.validateHobby()}
						onChange={(event) => {
							JoinStore.setJoin('hobby', event.target.value);
							JoinStore.validateHobby();
						}}
					/>
					{isHobby !== null && !isHobby && (
						<Form.Control.Feedback type="invalid">취미를 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						성별 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<div className="gender-wrap">
						<Form.Check
							type="radio"
							inline
							name="group1"
							label="여성"
							isInvalid={isGender !== null && !isGender}
							checked={join.gender === 'FEMALE'}
							onBlur={() => JoinStore.validateGender()}
							onChange={() => {
								JoinStore.setJoin('gender', 'FEMALE');
								JoinStore.validateGender();
							}}
						/>
						<Form.Check
							type="radio"
							inline
							name="group1"
							label="남성"
							isInvalid={isGender !== null && !isGender}
							checked={join.gender === 'MALE'}
							onBlur={() => JoinStore.validateGender()}
							onChange={() => {
								JoinStore.setJoin('gender', 'MALE');
								JoinStore.validateGender();
							}}
						/>
						{isGender !== null && !isGender && (
							<Form.Control.Feedback type="invalid">성별을 선택해 주세요.</Form.Control.Feedback>
						)}
					</div>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						이메일 주소 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="email"
						placeholder="이메일 주소를 입력해 주세요."
						isInvalid={(isEmail !== null && !isEmail) || (isEmailCheck !== null && !isEmailCheck)}
						value={join.email}
						onBlur={() => JoinStore.validateEmail()}
						onChange={(event) => {
							JoinStore.validateEmail();
							JoinStore.setJoin('email', event.target.value);
						}}
					/>

					{isEmail !== null && !isEmail && (
						<Form.Control.Feedback type="invalid">이메일을 입력해 주세요.</Form.Control.Feedback>
					)}
					{isEmail !== null && isEmail && isEmailCheck !== null && !isEmailCheck && (
						<Form.Control.Feedback type="invalid">이메일을 정확하게 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>키</Form.Label>
					<Form.Control
						type="text"
						placeholder="키를 입력해 주세요."
						value={join.height || ''}
						onChange={(event) => JoinStore.setJoin('height', event.target.value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>인스타그램 아이디</Form.Label>
					<Form.Control
						type="text"
						placeholder="인스타그램 아이디를 입력해 주세요."
						value={join.instagramId || ''}
						onChange={(event) => JoinStore.setJoin('instagramId', event.target.value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>알게 된 경로</Form.Label>
					<Form.Control
						type="text"
						placeholder="알게 된 경로를 입력해 주세요."
						value={join.knownPath || ''}
						onChange={(event) => {
							JoinStore.setJoin('knownPath', event.target.value);
						}}
					/>
				</Form.Group>
			</Form>

			<Button
				variant="dark"
				className="mt-5 w-100"
				disabled={!JoinStore.isMyPageValidateFieldsValid()}
				onClick={() => updateInfo()}>
				회원 정보 수정
			</Button>
			<div className="deactivate-wrap">
				<p className="deactivate" onClick={() => setOpenPopup(true)}>
					탈퇴하기
				</p>
			</div>

			<Popup header="" show={openPopup}>
				<p>정말 탈퇴하시겠어요?</p>

				<Button className="me-3" variant="outline-dark" onClick={() => setOpenPopup(false)}>
					아니요
				</Button>
				<Button variant="dark" onClick={() => userDeactivate()}>
					네, 탈퇴할게요
				</Button>
			</Popup>
		</Container>
	);
};

export default observer(MyPage);
