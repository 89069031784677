import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Table, Figure, Form } from 'react-bootstrap';
import AdminContainer from '../../components/container/AdminContainer';
import ListRenderer from '../../components/listRenderer/ListRenderer';
import { PartyStore, usePartyStore } from '../../store/admin/PartyStore';
import '../css/Admin.css';
import CustomPagination from '../../components/CustomPagination/CustomPagination';
import Filter from '../../Filter';

const ProductList = () => {
	const [currentPage, setCurrentPage] = useState<number>(1);

	const navigate = useNavigate();
	const { partyList, pagination, sort } = usePartyStore();
	const { toDate, toTime, dateTimeLocal } = Filter();

	const closeParty = (id: number) => {
		PartyStore.closeParty(id);
	};

	useEffect(() => {
		PartyStore.getPartyList();
	}, []);

	useEffect(() => {
		PartyStore.setPagination('currentPage', currentPage);
	}, [currentPage]);

	return (
		<AdminContainer>
			<div>
				<div className="product-list-btn-wrap">
					<Button
						className="mb-3"
						variant="dark"
						onClick={() => {
							navigate('/admin/product/newProduct');
							PartyStore.setPartyId(null);
						}}>
						상품 추가
					</Button>
					<div>
						<Form.Select
							value={sort}
							onChange={(event) => {
								PartyStore.setSort(event.target.value);
							}}>
							<option value="ASC">오름차순</option>
							<option value="DESC">내림차순</option>
						</Form.Select>
						<Form.Select
							value={pagination.size}
							onChange={(event) => PartyStore.setPagination('size', parseInt(event.target.value, 10))}>
							<option value={30}>30</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
						</Form.Select>
					</div>
				</div>
				<Table striped bordered hover className="product-list-table">
					<thead>
						<tr>
							<th style={{ width: '5%' }}>ID</th>
							<th style={{ width: '40%' }}>제목</th>
							<th style={{ width: '25%' }}>장소 및 일시</th>
							<th style={{ width: '10%' }}>금액</th>
							<th style={{ width: '10%' }}>인원수</th>
							<th style={{ width: '5%' }}>상세</th>
							<th style={{ width: '5%' }}>종료</th>
						</tr>
					</thead>
					<tbody>
						<ListRenderer
							data={partyList}
							renderItems={(item) => (
								<tr>
									<td>{item.id}</td>
									<td>
										<Figure.Image
											width={180}
											height={180}
											alt="main-image"
											src={item.mainImageUrl}
										/>
										<span> {item.title}</span>
									</td>
									<td>
										<p>장소 : {item.place.address}</p>
										<p>
											시작일 : {toDate(dateTimeLocal(item.schedule.startAt))}{' '}
											{toTime(dateTimeLocal(item.schedule.startAt))}
										</p>
										<p>
											종료일 : {toDate(dateTimeLocal(item.schedule.endAt))}{' '}
											{toTime(dateTimeLocal(item.schedule.endAt))}
										</p>
									</td>
									<td>
										<p>남성 : {item.entryFee.male}원</p>
										<p>여성 : {item.entryFee.female}원</p>
									</td>
									<td>
										<p>
											남성 : {item.recruitment.maleRecruited}/{item.recruitment.maleTO}
										</p>
										<p>
											여성 : {item.recruitment.femaleRecruited}/{item.recruitment.femaleTO}
										</p>
									</td>
									<td>
										<Button
											variant="dark"
											className="w-100"
											onClick={() => {
												navigate(`/admin/product/${item.id}`);
												PartyStore.setPartyId(item.id);
											}}>
											상세
										</Button>
									</td>
									<td>
										<Button variant="dark" className="w-100" onClick={() => closeParty(item.id)}>
											종료
										</Button>
									</td>
								</tr>
							)}
						/>
					</tbody>
				</Table>
			</div>
			<CustomPagination
				pageCount={pagination.totalPages}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
		</AdminContainer>
	);
};

export default observer(ProductList);
