import React, { useState, useEffect } from 'react';
import BootstrapPagination from 'react-bootstrap/Pagination';
import './CustomPagination.css';

interface Props {
	pageCount: number;
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const CustomPagination: React.FC<Props> = (props: Props) => {
	const { pageCount, currentPage, setCurrentPage } = props;
	const [pageNumbers, setPageNumbers] = useState<Array<number>>([]);
	const [loadNewNumbers, setLoadNewNumbers] = useState<string>('');

	const getPageNumbers = (start: number, end: number): Array<number> => {
		return Array(end - start)
			.fill(1)
			.map((_, idx) => start + idx);
	};

	useEffect(() => {
		const recalculateAndAssign = (): void => {
			const initializeFirstPages = (): void => {
				const checkEnd = currentPage + 10 > pageCount;
				const firstDigit = (currentPage % 10) - 1;
				const startIndex = currentPage - firstDigit;
				const recalculated = getPageNumbers(startIndex, checkEnd ? pageCount + 1 : currentPage + 10);
				setPageNumbers(recalculated);
			};

			const initializeLastPages = (): void => {
				const endIndex = (currentPage % 10) - 1;
				const recalculated = getPageNumbers(currentPage - endIndex, currentPage + 1);
				setPageNumbers(recalculated);
			};

			if (loadNewNumbers === 'first') {
				initializeFirstPages();
			} else if (loadNewNumbers === 'last') {
				initializeLastPages();
			} else if (loadNewNumbers === 'previous' || loadNewNumbers === 'next') {
				if (currentPage === 1) {
					initializeFirstPages();
				} else if (currentPage === pageCount) {
					initializeLastPages();
				} else {
					const firstDigit = (currentPage % 10) - 1;
					const startIndex = currentPage - firstDigit;
					const isOff = startIndex + 10 > pageCount;
					const recalculated = getPageNumbers(startIndex, isOff ? pageCount + 1 : startIndex + 10);
					setPageNumbers(recalculated);
				}
			} else {
				initializeFirstPages();
			}
		};

		recalculateAndAssign();
	}, [loadNewNumbers, pageCount]);

	const goToFirstPage = (): void => {
		setCurrentPage(1);
		setLoadNewNumbers('first');
	};

	const goToPreviousPage = (): void => {
		const isLessThanTheFirstPage = currentPage - 10 < 1;
		setCurrentPage(isLessThanTheFirstPage ? 1 : currentPage - 10);
		setLoadNewNumbers('previous');
	};

	const goToPage = (pageNumber: number): void => {
		window.location.href = `#${pageNumber}`;
		setCurrentPage(pageNumber);
	};

	const goToNextPage = (): void => {
		const isGreaterThanTheLastPage = currentPage + 10 > pageCount;
		setCurrentPage(isGreaterThanTheLastPage ? pageCount : currentPage + 10);
		setLoadNewNumbers('next');
	};

	const goToLastPage = (): void => {
		setCurrentPage(pageCount);
		setLoadNewNumbers('last');
	};

	return (
		<div>
			{pageCount > 0 ? (
				<BootstrapPagination className="customPagination">
					<BootstrapPagination.First disabled={currentPage === 1} onClick={() => goToFirstPage()} />
					<BootstrapPagination.Prev disabled={currentPage - 1 === 0} onClick={() => goToPreviousPage()} />
					{pageNumbers.map((current) => (
						<BootstrapPagination.Item
							key={current}
							active={current === currentPage}
							onClick={() => goToPage(current)}>
							{current}
						</BootstrapPagination.Item>
					))}
					<BootstrapPagination.Next disabled={currentPage + 1 > pageCount} onClick={() => goToNextPage()} />
					<BootstrapPagination.Last disabled={currentPage === pageCount} onClick={() => goToLastPage()} />
				</BootstrapPagination>
			) : null}
		</div>
	);
};

export default CustomPagination;
