import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form } from 'react-bootstrap';
import AdminContainer from '../../components/container/AdminContainer';
import FormField from '../../components/Form/FormField';
import FormRow from '../../components/Form/FormRow';
import '../css/Admin.css';
import { useQuestionStore } from '../../store/admin/QuestionStore';
import QuestionRepository from '../../repositories/admin/QuestionRepository';
import { QuestionDetailInterface } from '../../types/types';
import Filter from '../../Filter';

const ReportDetail = () => {
	const { questionId } = useQuestionStore();
	const { toDate, toTime, dateTimeLocal } = Filter();
	const { getQuestion, createAnswer } = QuestionRepository();

	const [detail, setDetail] = useState<QuestionDetailInterface>();

	const fetchAndHandleData = async (): Promise<void> => {
		if (questionId !== null) {
			try {
				const { data } = await getQuestion(questionId);
				setDetail(data.data);
			} catch (error) {
				alert('error');
			}
		}
	};

	const saveAnswer = async (): Promise<void> => {
		if (detail) {
			try {
				const param = { answerContent: detail.answerContent, note: detail.note };
				await createAnswer(detail.questionId, param);
				alert('저장 완료');
			} catch (error) {
				alert('답변에 실패하였습니다.');
			}
		}
	};

	useEffect(() => {
		fetchAndHandleData();
	}, []);

	return (
		<AdminContainer>
			<div className="detail-info-wrap">
				<h2>상세정보</h2>
				{detail !== undefined && (
					<div className="detail-info-form-wrap">
						<Form>
							<FormRow>
								<FormField label="글번호" className="basic-form label-form">
									<div className="question-detail-wrap">
										<p>{detail.questionId}</p>
									</div>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField label="제목" className="basic-form label-form">
									<div className="question-detail-wrap">
										<p>{detail.title}</p>
									</div>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField label="작성자 닉네임" className="basic-form label-form">
									<div className="question-detail-wrap">
										<p>{detail.nickname}</p>
									</div>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField label="글 내용" className="basic-form label-form">
									<Form.Group>
										<Form.Control
											readOnly
											type="text"
											as="textarea"
											style={{ height: '200px' }}
											value={detail.questionContent}
										/>
									</Form.Group>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField label="작성일" className="basic-form label-form">
									<div className="question-detail-wrap">
										<p>
											{toDate(dateTimeLocal(detail.createdAt))}{' '}
											{toTime(dateTimeLocal(detail.createdAt))}
										</p>
									</div>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField label="답변 여부" className="basic-form label-form">
									<div className="question-detail-wrap">
										<p>{detail.isAnswered === true ? '답변 완료' : '미답변'}</p>
									</div>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField label="답변일" className="basic-form label-form">
									<div className="question-detail-wrap">
										<p>
											{toDate(dateTimeLocal(detail.answeredAt))}{' '}
											{toTime(dateTimeLocal(detail.answeredAt))}
										</p>
									</div>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField label="답변 내용" className="basic-form label-form">
									<Form.Group>
										<Form.Control
											type="text"
											as="textarea"
											style={{ height: '200px' }}
											value={detail.answerContent}
											onChange={(e) => setDetail({ ...detail, answerContent: e.target.value })}
										/>
									</Form.Group>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField label="메모" className="basic-form label-form">
									<Form.Group>
										<Form.Control
											type="text"
											value={detail.note}
											onChange={(e) => setDetail({ ...detail, note: e.target.value })}
										/>
									</Form.Group>
								</FormField>
							</FormRow>
						</Form>
					</div>
				)}

				<Button variant="dark" className="save-btn" onClick={() => saveAnswer()}>
					저장
				</Button>
			</div>
		</AdminContainer>
	);
};

export default observer(ReportDetail);
