import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import Masonry from 'react-masonry-css';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Container from '../components/container/Container';
import { useAuthStore } from '../store/AuthStore';
import { ReviewStore, useReviewStore } from '../store/ReviewStore';
import ReviewWriteModal from './ReviewWriteModal';
import './css/Review.css';
import Popup from '../components/Popup/Popup';

const Review = () => {
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { userId } = useAuthStore();
	const { partyList } = useReviewStore();
	const [openPopup, setOpenPopup] = useState<boolean>(false);

	useEffect(() => {
		ReviewStore.getReviewList();
	}, []);

	return (
		<Container>
			<div className="d-flex justify-content-between align-items-end mb-5">
				<h2 className="title" style={{ margin: '0' }}>
					Review
				</h2>

				<Button
					variant="dark"
					style={{ height: '32px' }}
					onClick={() => {
						if (userId === null) {
							setOpenPopup(true);
						} else {
							setOpenModal(true);
						}
					}}>
					리뷰 작성하기
				</Button>
			</div>

			<ReviewWriteModal show={openModal} onHide={() => setOpenModal(false)} />

			{partyList.length > 0 ? (
				<Masonry
					breakpointCols={{ default: 2, 500: 1 }}
					className="review-masonry-grid"
					columnClassName="review-masonry-grid_column">
					{partyList.map((item: any) => (
						<Card key={item.id} style={{ marginBottom: '30px' }}>
							{item.images.length > 0 && (
								<Card.Img
									variant="top"
									style={{ width: '100%', objectFit: 'contain' }}
									src={item.images[0]}
								/>
							)}
							<Card.Body>
								<Card.Title style={{ fontWeight: 'bold' }}>{item.party.title}</Card.Title>
								<Card.Text>{item.note}</Card.Text>
								<Card.Text className="mt-2 text-end">
									{item.auth.nickname} / {item.auth.age} / {item.auth.job}
								</Card.Text>
							</Card.Body>
						</Card>
					))}
				</Masonry>
			) : (
				<div
					className="d-flex align-items-center justify-content-center"
					style={{ height: '400px', fontSize: '20px' }}>
					아직 작성된 리뷰가 없어요 🥺
				</div>
			)}

			<Popup header="" show={openPopup}>
				<p>로그인 후 신청이 가능합니다.</p>

				<Button className="me-3" variant="outline-dark" onClick={() => setOpenPopup(false)}>
					닫기
				</Button>
				<Button variant="dark" onClick={() => navigate('/login')}>
					확인
				</Button>
			</Popup>
		</Container>
	);
};

export default observer(Review);
