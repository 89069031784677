import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Nullable } from '../../types/base';
import './SubTab.css';

interface Props {
	tabValues: Array<{ eventKey: string; title: string; component: JSX.Element }>;
	tab: string;
	setTab: React.Dispatch<React.SetStateAction<string>>;
	onSelect?: (key: Nullable<string>) => void;
}

const SubTab: React.FC<Props> = (props) => {
	const { tabValues, tab, setTab, onSelect } = props;

	const handleTabChange = (key: Nullable<string>) => {
		if (key) {
			setTab(key);
		}
	};

	return (
		<Tabs activeKey={tab} className="subTab" onSelect={onSelect || handleTabChange} mountOnEnter unmountOnExit>
			{tabValues.map((tabValue) => (
				<Tab
					key={tabValue.eventKey}
					eventKey={tabValue.eventKey}
					title={tabValue.title}
					disabled={tab === tabValue.eventKey}>
					{tabValue.component}
				</Tab>
			))}
		</Tabs>
	);
};

SubTab.defaultProps = {
	onSelect: undefined,
};

export default SubTab;
