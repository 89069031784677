import { AxiosResponse } from 'axios';
import { JoinRepositoryInterface } from '../../types/base';
import { JoinInterface } from '../../types/types';
import DataRepository from '../DataRepository';

export default function JoinRepository(): Readonly<JoinRepositoryInterface> {
	const { post, get } = DataRepository();

	const signUp = (param: JoinInterface): Promise<AxiosResponse> => {
		const endPoint = `api/users`;
		return post(endPoint, param);
	};

	const idDuplicateCheck = (id: string): Promise<AxiosResponse> => {
		const endPoint = `api/users/duplicate?accountId=${id}`;
		return get(endPoint);
	};

	return Object.freeze({
		signUp,
		idDuplicateCheck,
	});
}
