import { AxiosResponse } from 'axios';
import { QuestionRepositoryInterface } from '../../types/base';
import { AnswerInterface, Question } from '../../types/types';
import DataRepository from '../DataRepository';

export default function QuestionRepository(): Readonly<QuestionRepositoryInterface> {
	const { get, update, post } = DataRepository();

	const createQuestion = (param: Question): Promise<AxiosResponse> => {
		const endPoint = `api/questions`;
		return post(endPoint, param);
	};

	const getQuestions = (query: string): Promise<AxiosResponse> => {
		const endPoint = `api/questions`;
		return get(endPoint, query);
	};

	const getQuestion = (id: number): Promise<AxiosResponse> => {
		const endPoint = `api/questions/${id}`;
		return get(endPoint);
	};

	const createAnswer = (id: number, param: AnswerInterface): Promise<AxiosResponse> => {
		const endPoint = `api/questions/${id}/answer`;
		return update(endPoint, param, 'PATCH');
	};

	return Object.freeze({
		getQuestions,
		getQuestion,
		createAnswer,
		createQuestion,
	});
}
