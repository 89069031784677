import { AxiosResponse } from 'axios';
import { LoginInterface, UserInfo } from '../types/types';
import { AuthRepositoryInterface } from '../types/base';
import DataRepository from './DataRepository';

export default function AuthRepository(): Readonly<AuthRepositoryInterface> {
	const { get, post, update } = DataRepository();

	const login = (param: LoginInterface): Promise<AxiosResponse> => {
		const endPoint = `api/users/login`;

		return post(endPoint, param);
	};

	const refreshJwtToken = (refreshToken: string): Promise<AxiosResponse> => {
		const endPoint = `api/users/login`;
		const formData = new FormData();
		formData.append('refreshJwtToken', refreshToken);

		return post(endPoint, formData);
	};

	const logout = (refreshToken: string): Promise<AxiosResponse> => {
		const endPoint = `api/users/logout`;
		const formData = new FormData();
		formData.append('refreshJwtToken', refreshToken);

		return post(endPoint, formData);
	};

	const getUserInfo = (): Promise<AxiosResponse> => {
		const endPoint = `api/users`;

		return get(endPoint);
	};

	const updateUserInfo = (param: UserInfo): Promise<AxiosResponse> => {
		const endPoint = `api/users`;

		return update(endPoint, param, 'PATCH');
	};

	const emailVerificationCode = (email: string): Promise<AxiosResponse> => {
		const endPoint = `api/emails/${email}/codes`;

		return post(endPoint);
	};

	const getId = (email: string, code: string): Promise<AxiosResponse> => {
		const endPoint = `api/emails/${email}/user?code=${code}`;

		return get(endPoint);
	};

	const patchPassword = (
		email: string,
		param: { accountId: string; code: string; password: string }
	): Promise<AxiosResponse> => {
		const endPoint = `api/emails/${email}/password`;

		return update(endPoint, param, 'PATCH');
	};

	const deactivate = (): Promise<AxiosResponse> => {
		const endPoint = `api/users/deactivate`;
		return post(endPoint);
	};

	return Object.freeze({
		login,
		refreshJwtToken,
		logout,
		getUserInfo,
		updateUserInfo,
		emailVerificationCode,
		getId,
		patchPassword,
		deactivate,
	});
}
