import React, { useState } from 'react';
import { Card, Carousel, Figure } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import Container from '../components/container/Container';

import party_3 from '../static/party_3.png';
import main from '../static/main_image.jpg';
import restaurant from '../static/restaurant.jpg';

import slide1 from '../static/party1.jpg';
import slide2 from '../static/party3.jpg';
import slide3 from '../static/party5.jpg';
import slide4 from '../static/party6.jpg';
import slide5 from '../static/party10.jpg';
import slide6 from '../static/party13.jpg';
import slide7 from '../static/party15.jpg';

import './css/Main.css';
import 'swiper/swiper-bundle.css';

import SubTab from '../components/SubTab/SubTab';
import CategoryList from './category/CategoryList';

enum TABS {
	ALL = 'ALL',
	PARTY = 'PARTY',
	WINE = 'WINE',
	TRIP = 'TRIP',
	CLASS = 'CLASS',
}

const Main = () => {
	const [tab, setTab] = useState<string>(TABS.ALL);

	const tabValues = [
		{ eventKey: 'ALL', title: 'ALL', component: <CategoryList type={tab} /> },
		{ eventKey: 'PARTY', title: '파티', component: <CategoryList type={tab} /> },
		{ eventKey: 'WINE', title: '모임', component: <CategoryList type={tab} /> },
		{ eventKey: 'TRIP', title: '여행', component: <CategoryList type={tab} /> },
		{ eventKey: 'CLASS', title: '클래스', component: <CategoryList type={tab} /> },
	];

	return (
		<>
			<Figure className="main-image w-100">
				<Figure.Image alt="poster" src={main} className="w-100" />
			</Figure>
			<Container>
				<h4>Networking party 🎉</h4>
				<SubTab tabValues={tabValues} tab={tab} setTab={setTab} />

				<div className="text-wrap">
					<h4>Text Review 📝</h4>
				</div>
				<div className="slide-wrap">
					<Swiper
						className="mySwiper"
						modules={[Autoplay]}
						autoplay={{
							delay: 3000,
							disableOnInteraction: true,
							pauseOnMouseEnter: true,
						}}
						spaceBetween={10}
						slidesPerView={1}
						breakpoints={{
							768: {
								slidesPerView: 3.5,
								spaceBetween: 10,
							},
							360: {
								slidesPerView: 1,
								spaceBetween: 5,
							},
						}}>
						<SwiperSlide>
							<Card>
								<Card.Body>
									<Card.Title>29세/남</Card.Title>
									<Card.Text>
										나이가 들수록 다른 분야에 있는 새로운 사람들 만나기가 쉽지 않은데 오랜만에
										새로운 사람들과 친해질 수 있어서 너무 좋았습니다.
									</Card.Text>
								</Card.Body>
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card>
								<Card.Body>
									<Card.Title>30세/여</Card.Title>
									<Card.Text>
										코로나 때문에 모이기 쉽지 않았는데 새로운 사람들과 만날수 있은 기회를 갖게
										되어서 좋았고 특히 제옆에 훈남분이 앉으셔서 오랜만에 설레고 좋았습니다 💕
									</Card.Text>
								</Card.Body>
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card>
								<Card.Body>
									<Card.Title>29세/남</Card.Title>
									<Card.Text>
										다양한 분야의 사람들과 오랜만에 즐거운 시간 보냈네요! 다들 미인분들이셔서 더욱
										즐거웠던 것 같습니다. 가자고 앞으로도 번창하세요!!
									</Card.Text>
								</Card.Body>
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card>
								<Card.Body>
									<Card.Title>25세/여</Card.Title>
									<Card.Text>
										오랜만에 새로운 사람들과 함께 이야기 나눌 수 있는 값진 시간이었습니다! 가자고
										아니었다면 이렇게 다양한 분야의 분들을 만나기 쉽지 않았을텐데 좋은 자리
										만들어주셔서 감사합니다:) -!
									</Card.Text>
								</Card.Body>
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card>
								<Card.Body>
									<Card.Title>30세/남</Card.Title>
									<Card.Text>
										좋은 사람들과의 좋은 분위기의 자리를 이렇게 경험할 수 있어 기분 좋아지는
										저녁이었습니다. 다음에도 또 참여할게요! :)
									</Card.Text>
								</Card.Body>
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card>
								<Card.Body>
									<Card.Title>29세/여</Card.Title>
									<Card.Text>
										초면인 사람끼리 모여서 어색하지 않을까 걱정했는데, 오히려 다양한 주제로 대화를
										나눌수 있어 재미있는 경험이었습니다;)
									</Card.Text>
								</Card.Body>
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card>
								<Card.Body>
									<Card.Title>29세/여</Card.Title>
									<Card.Text>
										첫만남에 어색함은 잠시였고 너무 유쾌하고 좋으신분들이라 즐거운 시간이였어요.
										새로운 사람 만나기가 쉽지않은데 가자고 덕분에 좋은시간 가졌습니다! 감사해요:)
									</Card.Text>
								</Card.Body>
							</Card>
						</SwiperSlide>
						<SwiperSlide>
							<Card>
								<Card.Body>
									<Card.Title>29세/남</Card.Title>
									<Card.Text>
										야근에 스트레스를 받은 날에 가자고를 통해 뜻밖의 즐거움을 얻었습니다. 처음 본
										분에게 생일 축하 노래를 불러주는 훈훈함까지 너무 힐링됐어요~
									</Card.Text>
								</Card.Body>
							</Card>
						</SwiperSlide>
					</Swiper>
				</div>
				<div className="slide-wrap">
					<div className="text-wrap">
						<h4>Photo Review 📷</h4>
					</div>
					<Carousel indicators={false}>
						<Carousel.Item interval={3000}>
							<img className="d-block w-100" src={slide1} alt="slide" />
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img className="d-block w-100" src={slide2} alt="slide" />
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img className="d-block w-100" src={slide3} alt="slide" />
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img className="d-block w-100" src={slide4} alt="slide" />
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img className="d-block w-100" src={slide5} alt="slide" />
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img className="d-block w-100" src={slide6} alt="slide" />
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img className="d-block w-100" src={slide7} alt="slide" />
						</Carousel.Item>
					</Carousel>
				</div>
				<div className="sub-section">
					<div className="text-wrap">
						<h4>함께하면 더 행복한 🍀</h4>
						<p>지루한 일상 속 소소한 행복을 함께 나누는 시간</p>
					</div>
					<div className="img-container-wrap">
						<Figure className="img-wrap">
							<Figure.Image alt="poster" src={party_3} className="w-100" />
						</Figure>
						<Figure className="img-wrap top_img">
							<Figure.Image alt="poster" src={restaurant} className="w-100" />
						</Figure>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Main;
