import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
	label: string | React.ReactNode;
}

const FormLabel: React.FC<Props> = (props) => {
	const { label } = props;

	return (
		<Form.Label column xs={1} className="basic-label">
			{label}
		</Form.Label>
	);
};

export default FormLabel;
