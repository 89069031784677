import { observer } from 'mobx-react';
import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Container from '../components/container/Container';
import { AuthStore, useAuthStore } from '../store/AuthStore';
import './css/Login.css';

const Login = (): JSX.Element => {
	const navigate = useNavigate();
	const { loginData } = useAuthStore();

	const login = async () => {
		try {
			await AuthStore.login();
			navigate('/main');
		} catch (error) {
			alert('아이디와 비밀번호를 확인해주세요.');
		}
	};

	const handleOnKeyPress = (event: any) => {
		if (event.key === 'Enter') {
			login();
		}
	};

	return (
		<Container>
			<h2 className="title">Login</h2>
			<Form className="login-form-wrap">
				<Form.Group>
					<Form.Label>Id</Form.Label>
					<Form.Control
						type="text"
						id="id-input"
						value={loginData.accountId}
						onChange={(event) => AuthStore.setLogin('accountId', event.target.value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Password</Form.Label>
					<Form.Control
						type="password"
						id="password-input"
						value={loginData.password}
						onChange={(event) => AuthStore.setLogin('password', event.target.value)}
						onKeyDown={(event) => handleOnKeyPress(event)}
					/>
				</Form.Group>
			</Form>
			<Button variant="dark" className="w-100" onClick={() => login()}>
				login
			</Button>

			<div className="d-flex justify-content-between">
				<div className="d-flex">
					<p className="join me-2" onClick={() => navigate('/login/findId')}>
						아이디 찾기
					</p>
					<p style={{ marginTop: '10px', fontWeight: 700 }}>|</p>
					<p className="join ms-2" onClick={() => navigate('/login/findPassword')}>
						비밀번호 찾기
					</p>
				</div>

				<p className="join" onClick={() => navigate('/join')}>
					회원가입
				</p>
			</div>
		</Container>
	);
};

export default observer(Login);
