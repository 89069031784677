import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Container from '../components/container/Container';
import CustomPagination from '../components/CustomPagination/CustomPagination';
import FormField from '../components/Form/FormField';
import FormRow from '../components/Form/FormRow';
import ListRenderer from '../components/listRenderer/ListRenderer';
import Popup from '../components/Popup/Popup';
import Filter from '../Filter';
import QuestionRepository from '../repositories/admin/QuestionRepository';
import { QuestionStore, useQuestionStore } from '../store/admin/QuestionStore';
import { QuestionDetailInterface, QuestionListInterface } from '../types/types';

const Inquiry = () => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [size, setSize] = useState<number>(30);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalCnt, setTotalCnt] = useState<number>(0);
	const [sort, setSort] = useState<string>('DESC');
	const [questionList, setQuestionList] = useState<Array<QuestionListInterface>>([]);
	const [id, setId] = useState<number>(0);
	const [detail, setDetail] = useState<QuestionDetailInterface>();

	const { toDate, toTime, dateTimeLocal } = Filter();
	const { getQuestions, getQuestion } = QuestionRepository();
	const { question, successPopup, failurePopup, errorMessage } = useQuestionStore();

	const fetchAndHandleData = async (): Promise<void> => {
		try {
			const query = `?direction=${sort}&page=${currentPage}&size=${size}&type=INQUIRY`;
			const { data } = await getQuestions(query);
			const { totalCount } = data;

			setQuestionList(data.data.data);
			setTotalCnt(totalCount);
			setTotalPages(Math.ceil(totalCount / size));
		} catch (error) {
			alert('error');
		}
	};

	const fetchAndHandleDetailData = async (itemId: number): Promise<void> => {
		try {
			const { data } = await getQuestion(itemId);
			setDetail(data.data);
		} catch (error) {
			alert('error');
		}
	};

	useEffect(() => {
		fetchAndHandleData();
	}, []);

	useEffect(() => {
		fetchAndHandleData();
	}, [sort, currentPage, size]);

	useEffect(() => {
		if (openModal) {
			QuestionStore.cleanUp();
		}
	}, [openModal]);

	return (
		<Container>
			<div className="d-flex justify-content-between align-items-end mb-5">
				<h2 className="title" style={{ margin: '0' }}>
					문의
				</h2>
				<Button variant="dark" style={{ height: '32px' }} onClick={() => setOpenModal(true)}>
					문의하기
				</Button>
			</div>

			<div>
				<div className="product-list-btn-wrap justify-content-end mb-4">
					<div>
						<Form.Select
							value={sort}
							onChange={(event) => {
								setSort(event.target.value);
							}}>
							<option value="ASC">오름차순</option>
							<option value="DESC">내림차순</option>
						</Form.Select>
						<Form.Select value={size} onChange={(event) => setSize(parseInt(event.target.value, 10))}>
							<option value={30}>30</option>
							<option value={50}>50</option>
							<option value={100}>100</option>
						</Form.Select>
					</div>
				</div>
				<Table striped bordered hover className="product-list-table">
					<thead>
						<tr>
							<th style={{ width: '30%' }}>제목</th>
							<th style={{ width: '20%' }}>닉네임</th>
							<th style={{ width: '20%' }}>작성일</th>
							<th style={{ width: '15%' }}>답변여부</th>
						</tr>
					</thead>
					<tbody>
						<ListRenderer
							data={questionList}
							renderItems={(item) => (
								<>
									<tr
										style={{ cursor: 'pointer' }}
										onClick={() => {
											setIsOpen(!isOpen);
											setId(item.questionId);
											fetchAndHandleDetailData(item.questionId);
										}}>
										<td>{item.title}</td>
										<td>{item.nickname}</td>
										<td>
											{toDate(dateTimeLocal(item.createdAt))}{' '}
											{toTime(dateTimeLocal(item.createdAt))}
										</td>
										<td>{item.isAnswered === true ? '완료' : '미답변'}</td>
									</tr>
									{detail && isOpen && item.questionId === id && (
										<td colSpan={4}>
											<Card className="m-1">
												<Card.Body>
													<Card.Text> {item.questionContent}</Card.Text>
													{detail?.answerContent && (
														<>
															<hr />
															<Card.Text style={{ fontSize: '12px', color: 'gray' }}>
																{toDate(dateTimeLocal(detail.answeredAt))}{' '}
																{toTime(dateTimeLocal(detail.answeredAt))}
															</Card.Text>
															<Card.Text>{detail.answerContent}</Card.Text>
														</>
													)}
												</Card.Body>
											</Card>
										</td>
									)}
								</>
							)}
						/>
					</tbody>
				</Table>
			</div>

			{openModal && (
				<Modal centered show={openModal} onHide={() => setOpenModal(false)}>
					<Modal.Header>문의 작성</Modal.Header>
					<Modal.Body>
						<Form>
							<FormRow>
								<FormField
									label={
										<>
											<span style={{ color: 'red', marginRight: '0.25rem' }}>*</span> 제목
										</>
									}
									className="basic-form label-form">
									<Form.Control
										type="text"
										value={question.title}
										onChange={(event) => QuestionStore.setQuestion('title', event.target.value)}
									/>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField
									label={
										<>
											<span style={{ color: 'red', marginRight: '0.25rem' }}>*</span> 핸드폰 번호
										</>
									}
									className="basic-form label-form">
									<Form.Control
										type="text"
										value={question.phoneNumber}
										onChange={(event) =>
											QuestionStore.setQuestion('phoneNumber', event.target.value)
										}
									/>
								</FormField>
							</FormRow>
							<FormRow>
								<FormField
									label={
										<>
											<span style={{ color: 'red', marginRight: '0.25rem' }}>*</span> 내용
										</>
									}>
									<Form.Control
										as="textarea"
										placeholder="내용을 입력해 주세요."
										rows={10}
										value={question.questionContent}
										onChange={(event) =>
											QuestionStore.setQuestion('questionContent', event.target.value)
										}
									/>
								</FormField>
							</FormRow>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button
							style={{ width: '100%', height: '40px' }}
							variant="dark"
							onClick={() => QuestionStore.createQuestion('INQUIRY')}>
							작성하기
						</Button>
					</Modal.Footer>

					<Popup header="작성 완료" show={successPopup}>
						<Button
							variant="dark"
							onClick={() => {
								QuestionStore.openPopup('success', false);
								QuestionStore.cleanUp();
								setOpenModal(false);
								fetchAndHandleData();
							}}>
							확인
						</Button>
					</Popup>

					<Popup header="작성 실패" show={failurePopup}>
						<div>{errorMessage}</div>
						<Button
							variant="dark"
							onClick={() => {
								QuestionStore.openPopup('fail', false);
							}}>
							확인
						</Button>
					</Popup>
				</Modal>
			)}
			<CustomPagination pageCount={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
		</Container>
	);
};

export default observer(Inquiry);
