import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Form, Modal, Button, Figure } from 'react-bootstrap';
import Select from 'react-select';
import FormField from '../components/Form/FormField';
import FormRow from '../components/Form/FormRow';
import ListRenderer from '../components/listRenderer/ListRenderer';
import Popup from '../components/Popup/Popup';
import AttachmentRepository from '../repositories/admin/AttachmentRepository';
import { useAuthStore } from '../store/AuthStore';
import { ReviewStore, useReviewStore } from '../store/ReviewStore';

interface Props {
	show: boolean;
	onHide: () => void;
}
const ReviewWriteModal: React.FC<Props> = (props: Props) => {
	const { show, onHide } = props;

	const { userId } = useAuthStore();
	const { partyData, reviewForm, successPopup, failurePopup, errorMessage } = useReviewStore();

	const { uploadFile } = AttachmentRepository();

	const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		if (event.target.files) {
			const file = event.target.files[0];

			try {
				const { data } = await uploadFile(file);
				ReviewStore.setImages(data.data);
			} catch (error) {
				alert('업로드에 실패하였습니다.');
			}
		}
	};

	useEffect(() => {
		if (show && userId) {
			ReviewStore.getSubscriptions(userId);
		}
	}, [show]);

	return (
		<Modal
			show={show}
			onHide={() => {
				onHide();
				ReviewStore.cleanUp();
			}}
			centered>
			<Modal.Header closeButton>
				<Modal.Title>리뷰 작성</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form className="ps-2 pe-2">
					<FormRow>
						<FormField label="이미지" className="basic-form label-form">
							<Form.Group>
								<Button variant="dark" style={{ height: '35px' }}>
									<label htmlFor="file-upload1" style={{ cursor: 'pointer' }}>
										파일 등록
									</label>
									<input
										type="file"
										id="file-upload1"
										className="d-none"
										accept=".jpg, .png, .jpeg, pdf"
										onChange={(event) => handleImageUpload(event)}
									/>
								</Button>
								<div>
									{reviewForm.images && (
										<ListRenderer
											data={reviewForm.images}
											renderItems={(image) => (
												<Figure className="mt-3">
													<Figure.Image width={200} alt="img" src={image} />
												</Figure>
											)}
										/>
									)}
								</div>
							</Form.Group>
						</FormField>
					</FormRow>
					<FormRow>
						<FormField
							label={
								<>
									<span style={{ color: 'red', marginRight: '0.25rem' }}>*</span> 내가 신청한 모임
								</>
							}>
							<Select
								isClearable
								isSearchable={false}
								placeholder="모임을 선택해 주세요."
								options={partyData}
								onChange={(event) => {
									if (event?.value) {
										ReviewStore.setPartyId(event.value);
									}
								}}
							/>
						</FormField>
					</FormRow>
					<FormRow>
						<FormField
							label={
								<>
									<span style={{ color: 'red', marginRight: '0.25rem' }}>*</span> 후기 작성
								</>
							}>
							<Form.Control
								as="textarea"
								placeholder="내용을 입력해 주세요."
								rows={10}
								value={reviewForm.note}
								onChange={(event) => ReviewStore.setNote(event.target.value)}
							/>
						</FormField>
					</FormRow>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button
					style={{ width: '100%', height: '40px' }}
					variant="dark"
					onClick={() => ReviewStore.postReview()}>
					작성하기
				</Button>
			</Modal.Footer>

			<Popup header="작성 완료" show={successPopup}>
				<Button
					variant="dark"
					onClick={() => {
						ReviewStore.openPopup('success', false);
						ReviewStore.cleanUp();
						window.location.reload();
					}}>
					확인
				</Button>
			</Popup>

			<Popup header="작성 실패" show={failurePopup}>
				<div>{errorMessage}</div>
				<Button
					variant="dark"
					onClick={() => {
						ReviewStore.openPopup('fail', false);
					}}>
					확인
				</Button>
			</Popup>
		</Modal>
	);
};

export default observer(ReviewWriteModal);
