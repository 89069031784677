import React from 'react';
import './Container.css';

interface Props {
	children: React.ReactNode;
}

const Container: React.FC<Props> = (props) => {
	const { children } = props;
	return <div id="gajago-container">{children}</div>;
};

export default Container;
