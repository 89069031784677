import { AxiosResponse } from 'axios';
import DataRepository from '../DataRepository';
import { AttachmentRepositoryInterface } from '../../types/base';

export default function AttachmentRepository(): Readonly<AttachmentRepositoryInterface> {
	const { post } = DataRepository();

	const uploadFile = (file: File): Promise<AxiosResponse> => {
		const endPoint = 'files';
		const formData = new FormData();
		formData.append('file', file);

		return post(endPoint, formData);
	};

	return Object.freeze({
		uploadFile,
	});
}
