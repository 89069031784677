import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Container from '../components/container/Container';
import Filter from '../Filter';
import AuthRepository from '../repositories/AuthRepository';
import { Nullable } from '../types/base';

const FindId = () => {
	const navigate = useNavigate();

	const [email, setEmail] = useState<string>('');
	const [id, setId] = useState<string>('');
	const [date, setDate] = useState<string>('');
	const [code, setCode] = useState<string>('');
	const [isEmail, setIsEmail] = useState<Nullable<boolean>>(null);
	const [isEmailCheck, setIsEmailCheck] = useState<Nullable<boolean>>(null);
	const [success, setSuccess] = useState<boolean>(false);
	const [findSuccess, setFindSuccess] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const { emailVerificationCode, getId } = AuthRepository();
	const { toDate, toTime, dateTimeLocal } = Filter();

	const verificationCode = async () => {
		try {
			await emailVerificationCode(email);
			setSuccess(true);
		} catch (error) {
			alert('코드 발송에 실패하였습니다.');
		}
	};

	const findId = async () => {
		try {
			const { data } = await getId(email, code);
			const { accountId, createdAt } = data[0];
			setId(accountId);
			setDate(createdAt);
			setFindSuccess(true);
		} catch (error) {
			alert('아이디 찾기에 실패하였습니다.');
		}
	};

	const validateEmail = () => {
		const regEx =
			// eslint-disable-next-line no-useless-escape
			/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;

		if (!email) {
			setIsEmail(false);
		} else {
			setIsEmail(true);

			if (!regEx.test(email)) {
				setIsDisabled(true);
			} else {
				setIsEmailCheck(true);
				setIsDisabled(false);
			}
		}
	};

	return (
		<Container>
			<h2 className="title">아이디 찾기</h2>

			<Form>
				{!findSuccess && !success ? (
					<>
						<Form.Group>
							<Form.Label>이메일</Form.Label>
							<Form.Control
								type="text"
								value={email}
								isValid={isEmail !== null && isEmail && isEmailCheck !== null && isEmailCheck}
								isInvalid={(isEmail !== null && !isEmail) || (isEmailCheck !== null && !isEmailCheck)}
								placeholder="이메일을 입력해 주세요."
								onChange={(event) => {
									validateEmail();
									setEmail(event.target.value);
								}}
								onBlur={() => validateEmail()}
							/>
							{isEmail !== null && !isEmail && (
								<Form.Control.Feedback type="invalid">이메일을 입력해 주세요.</Form.Control.Feedback>
							)}
							{isEmail !== null && isEmail && isEmailCheck !== null && !isEmailCheck && (
								<Form.Control.Feedback type="invalid">
									이메일을 정확하게 입력해 주세요.
								</Form.Control.Feedback>
							)}
						</Form.Group>
						<Button
							variant="dark"
							className="w-100 mt-3"
							disabled={isDisabled || !isEmail}
							onClick={() => verificationCode()}>
							코드 발송
						</Button>
					</>
				) : (
					<>
						<Form.Group className="mb-3">
							<Form.Label>이메일</Form.Label>
							<Form.Control
								type="text"
								value={email}
								onChange={(event) => setEmail(event.target.value)}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>코드 입력</Form.Label>
							<Form.Control
								type="text"
								placeholder="코드를 입력해 주세요."
								value={code}
								onChange={(event) => setCode(event.target.value)}
							/>
						</Form.Group>
						{!findSuccess ? (
							<Button variant="dark" className="w-100 mt-3" onClick={() => findId()}>
								아이디 찾기
							</Button>
						) : (
							<Button variant="dark" className="w-100 mt-3" onClick={() => navigate('/login')}>
								로그인하러 가기
							</Button>
						)}
					</>
				)}

				{findSuccess && (
					<Card className="mt-5">
						<Card.Body className="text-center">
							회원님의 가입 날짜는{' '}
							<span style={{ fontWeight: 'bold' }}>
								{toDate(dateTimeLocal(date).substring(0, date.length - 4))}{' '}
								{toTime(dateTimeLocal(date).substring(0, date.length - 4))}
							</span>
							이며,
							<br /> 아이디는 <span style={{ fontWeight: 'bold' }}>{id}</span> 입니다.
						</Card.Body>
					</Card>
				)}
			</Form>
		</Container>
	);
};

export default FindId;
