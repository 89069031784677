import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Container from '../components/container/Container';
import AuthRepository from '../repositories/AuthRepository';
import { Nullable } from '../types/base';

const FindPassword = () => {
	const navigate = useNavigate();

	const [email, setEmail] = useState<string>('');
	const [code, setCode] = useState<string>('');
	const [id, setId] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const [isEmail, setIsEmail] = useState<Nullable<boolean>>(null);
	const [isEmailCheck, setIsEmailCheck] = useState<Nullable<boolean>>(null);
	const [isId, setIsId] = useState<Nullable<boolean>>(null);
	const [isCode, setIsCode] = useState<Nullable<boolean>>(null);
	const [isPassword, setIsPassword] = useState<Nullable<boolean>>(null);

	const [success, setSuccess] = useState<boolean>(false);
	const [resetSuccess, setResetSuccess] = useState<boolean>(false);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);

	const { emailVerificationCode, patchPassword } = AuthRepository();

	const verificationCode = async () => {
		try {
			await emailVerificationCode(email);
			setSuccess(true);
		} catch (error) {
			alert('코드 발송에 실패하였습니다.');
		}
	};

	const resetPassword = async () => {
		try {
			const param = {
				accountId: id,
				code,
				password,
			};
			await patchPassword(email, param);
			setResetSuccess(true);
		} catch (error) {
			alert('비밀번호 재설정에 실패하였습니다.');
		}
	};

	const validateEmail = () => {
		const regEx =
			// eslint-disable-next-line no-useless-escape
			/^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;

		if (!email) {
			setIsEmail(false);
		} else {
			setIsEmail(true);

			if (!regEx.test(email)) {
				setIsEmailCheck(false);
			} else {
				setIsEmailCheck(true);
				setIsDisabled(false);
			}
		}
	};

	const validateId = () => {
		if (!id) {
			setIsId(false);
		} else {
			setIsId(true);
		}
	};

	const validateCode = () => {
		if (!code) {
			setIsCode(false);
		} else {
			setIsCode(true);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const checkPassword = (password: string) => {
		let charType = 0;
		if (/[a-z]/.test(password)) {
			charType += 1;
		}
		if (/[A-Z]/.test(password)) {
			charType += 1;
		}
		if (/[0-9]/.test(password)) {
			charType += 1;
		}
		// eslint-disable-next-line no-useless-escape
		if (/[`~!@#$%^&*|\\\‘\“;:\/?]/gi.test(password)) charType += 1;
		return !(password.length < 8 || password.length > 16 || charType < 3);
	};

	const validatePassword = () => {
		const isValidatePassword = checkPassword(password);

		setIsPassword(isValidatePassword);
	};

	return (
		<Container>
			<h2 className="title">비밀번호 찾기</h2>

			<Form>
				{!resetSuccess && !success ? (
					<>
						<Form.Group>
							<Form.Label>이메일</Form.Label>
							<Form.Control
								type="text"
								value={email}
								isValid={isEmail !== null && isEmail && isEmailCheck !== null && isEmailCheck}
								isInvalid={(isEmail !== null && !isEmail) || (isEmailCheck !== null && !isEmailCheck)}
								placeholder="이메일을 입력해 주세요."
								onChange={(event) => {
									validateEmail();
									setEmail(event.target.value);
								}}
								onBlur={() => validateEmail()}
							/>
							{isEmail !== null && !isEmail && (
								<Form.Control.Feedback type="invalid">이메일을 입력해 주세요.</Form.Control.Feedback>
							)}
							{isEmail !== null && isEmail && isEmailCheck !== null && !isEmailCheck && (
								<Form.Control.Feedback type="invalid">
									이메일을 정확하게 입력해 주세요.
								</Form.Control.Feedback>
							)}
						</Form.Group>
						<Button
							variant="dark"
							className="w-100 mt-3"
							disabled={isDisabled || !isEmail}
							onClick={() => verificationCode()}>
							코드 발송
						</Button>
					</>
				) : (
					<>
						<Form.Group className="mb-3">
							<Form.Label>아이디</Form.Label>
							<Form.Control
								type="text"
								placeholder="아이디를 입력해 주세요."
								isValid={isId !== null && isId}
								isInvalid={isId !== null && !isId}
								value={id}
								onChange={(event) => {
									validateId();
									setId(event.target.value);
								}}
								onBlur={() => validateId()}
							/>
							{isId !== null && !isId && (
								<Form.Control.Feedback type="invalid">아이디를 입력해 주세요.</Form.Control.Feedback>
							)}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>코드 입력</Form.Label>
							<Form.Control
								type="text"
								placeholder="코드를 입력해 주세요."
								isValid={isCode !== null && isCode}
								isInvalid={isCode !== null && !isCode}
								value={code}
								onChange={(event) => {
									validateCode();
									setCode(event.target.value);
								}}
								onBlur={() => validateCode()}
							/>
							{isCode !== null && !isCode && (
								<Form.Control.Feedback type="invalid">코드를 입력해 주세요.</Form.Control.Feedback>
							)}
						</Form.Group>
						<Form.Group>
							<Form.Label>비밀번호</Form.Label>
							<Form.Control
								type="password"
								placeholder="비밀번호를 입력해 주세요."
								isValid={isPassword !== null && isPassword}
								isInvalid={isPassword !== null && !isPassword}
								value={password}
								onChange={(event) => {
									setPassword(event.target.value);
									validatePassword();
								}}
								onBlur={() => validatePassword()}
							/>
							{isPassword !== null && !isPassword && (
								<Form.Control.Feedback type="invalid">
									영문(대소문자), 숫자, 특수문자 중 3가지 이상 사용하여, 8~16자로 입력해 주세요.
								</Form.Control.Feedback>
							)}
						</Form.Group>
						{!resetSuccess ? (
							<Button
								variant="dark"
								className="w-100 mt-3"
								disabled={
									(isId !== null && !isId) ||
									(isCode !== null && !isCode) ||
									(isPassword !== null && !isPassword)
								}
								onClick={() => resetPassword()}>
								비밀번호 재설정
							</Button>
						) : (
							<>
								<Card className="mt-5">
									<Card.Body className="text-center">비밀번호 재설정이 완료되었습니다.</Card.Body>
								</Card>

								<Button variant="dark" className="w-100 mt-3" onClick={() => navigate('/login')}>
									로그인하러 가기
								</Button>
							</>
						)}
					</>
				)}
			</Form>
		</Container>
	);
};

export default FindPassword;
