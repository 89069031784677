import { AxiosError } from 'axios';
import { createContext, useContext } from 'react';
import { runInAction, makeAutoObservable } from 'mobx';
import { Nullable } from '../../types/base';
import { Question } from '../../types/types';
import { initialQuestionForm } from '../constants';
import QuestionRepository from '../../repositories/admin/QuestionRepository';

class QuestionStoreImpl {
	questionId: Nullable<number> = null;
	question: Question = initialQuestionForm;
	errorMessage = '';
	successPopup = false;
	failurePopup = false;

	questionRepository = QuestionRepository();

	constructor() {
		makeAutoObservable(this);
	}

	cleanUp() {
		runInAction(() => {
			this.question = initialQuestionForm;
		});
	}

	setQuestionId(id: Nullable<number>): void {
		runInAction(() => {
			this.questionId = id;
		});
	}

	setQuestion(key: string, value: string) {
		runInAction(() => {
			this.question[key] = value;
		});
	}

	openPopup(key: string, isOpen: boolean) {
		runInAction(() => {
			if (key === 'success') {
				this.successPopup = isOpen;
			} else if (key === 'fail') {
				this.failurePopup = isOpen;
			}
		});
	}

	async createQuestion(type: string): Promise<void> {
		try {
			const param = {
				...this.question,
				type,
			};
			const { data } = await this.questionRepository.createQuestion(param);
			this.successPopup = true;
		} catch (error) {
			if (error instanceof AxiosError) {
				this.errorMessage = '필수 값을 모두 입력해 주세요.';
			}
			this.failurePopup = true;
		}
	}
}

export const QuestionStore = new QuestionStoreImpl();
const QuestionContext = createContext(QuestionStore);
export const useQuestionStore = (): QuestionStoreImpl => useContext(QuestionContext);
