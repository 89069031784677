import { AxiosError } from 'axios';
import { createContext, useContext } from 'react';
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import ReviewRepository from '../repositories/ReviewRepository';
import { Review } from '../types/types';
import { initialReviewForm } from './constants';

class ReviewStoreImpl {
	partyState = true;
	partyList: any = [];
	partyData: Array<{ value: number; label: string }> = [];
	reviewForm: Review = initialReviewForm;
	partyId = -1;
	size = 8;
	sort = '';

	errorMessage = '';

	successPopup = false;
	failurePopup = false;

	reviewRepository = ReviewRepository();

	constructor() {
		makeAutoObservable(this);
	}

	cleanUp() {
		runInAction(() => {
			this.reviewForm = initialReviewForm;
		});
	}

	setImages(image: string) {
		runInAction(() => {
			const temp = [];
			temp.push(image);
			this.reviewForm.images = temp;
		});
	}

	setNote(note: string) {
		runInAction(() => {
			this.reviewForm.note = note;
		});
	}

	setPartyId(id: number) {
		runInAction(() => {
			this.partyId = id;
		});
	}

	openPopup(key: string, isOpen: boolean) {
		runInAction(() => {
			if (key === 'success') {
				this.successPopup = isOpen;
			} else if (key === 'fail') {
				this.failurePopup = isOpen;
			}
		});
	}

	async getReviewList(): Promise<void> {
		try {
			// const query = `?size=${this.size}&sort=${this.sort}`;
			// const { data } = await this.reviewRepository.getReview(query);
			const { data } = await this.reviewRepository.getReview();
			this.partyList = data.data;
			console.log(data.data, '리뷰우우우데이터');
		} catch (error) {
			console.log('리뷰 목록을 불러오는데 실패하였습니다.');
		}
	}

	async getSubscriptions(userId: number): Promise<void> {
		try {
			const { data } = await this.reviewRepository.subscriptions(userId);

			const tempStateList = data.data.map((item: any) => {
				const { state } = item;
				return state;
			});

			const tempState = tempStateList.some((item: string) => item === 'APPROVED');

			if (tempState) {
				this.partyState = false;
			} else {
				this.partyState = true;
			}

			const tempApproved = data.data.filter((item: any) => item.state === 'APPROVED');

			const tempList = tempApproved.map((item: any) => {
				const { party } = item;
				return {
					value: party.id,
					label: party.title,
				};
			});

			this.partyData = tempList;
		} catch (error) {
			console.log('신청한 모임 조회에 실패하였습니다.');
		}
	}

	async postReview(): Promise<void> {
		try {
			await this.reviewRepository.postReview(this.partyId, this.reviewForm);
			this.successPopup = true;
		} catch (error) {
			if (error instanceof AxiosError) {
				this.errorMessage = '필수 값을 모두 입력해 주세요.';
				if (error.response?.data.reason === '이미 작성했습니다.') {
					this.errorMessage = '해당 모임은 이미 작성된 리뷰가 있습니다.';
				}
			}
			this.failurePopup = true;
		}
	}
}

export const ReviewStore = new ReviewStoreImpl();
const ReviewContext = createContext(ReviewStore);
export const useReviewStore = (): ReviewStoreImpl => useContext(ReviewContext);
