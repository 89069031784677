import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import React, { useEffect } from 'react';
import { Form, Button, Figure } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import Container from '../components/container/Container';
import AttachmentRepository from '../repositories/admin/AttachmentRepository';
import { JoinStore, useJoinSotre } from '../store/join/JoinStore';
import './css/Join.css';

const Join = () => {
	const navigate = useNavigate();
	const { uploadFile } = AttachmentRepository();
	const { join, passwordConfirm, joinValidation } = useJoinSotre();
	const {
		isAccountId,
		isIdDuplicateCheck,
		isPassword,
		isPasswordConfirm,
		isName,
		isNickname,
		isBirth,
		isPhoneNumber,
		isPhoneNumberCheck,
		isJob,
		isHobby,
		isGender,
		isEmail,
		isEmailCheck,
	} = joinValidation;

	const handleThumbnailFileDetails = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		if (event.target.files) {
			const file = event.target.files[0];

			try {
				const { data } = await uploadFile(file);
				JoinStore.setJoin('profileImage', data.data);
				JoinStore.validateProfileImage();
			} catch (error) {
				alert('업로드에 실패하였습니다.');
			}
		}
	};

	return (
		<Container>
			<div className="d-flex justify-content-between align-items-end mb-4">
				<h2 className="title" style={{ margin: '0' }}>
					회원가입
				</h2>
				<div>
					<span style={{ color: 'red' }}>*</span> 필수 정보
				</div>
			</div>

			<Form className="join-form-wrap">
				<Form.Group>
					<Form.Label>
						아이디 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="아이디를 입력해 주세요."
						minLength={5}
						maxLength={20}
						isValid={
							isAccountId !== null && isAccountId && isIdDuplicateCheck !== null && isIdDuplicateCheck
						}
						isInvalid={
							(isAccountId !== null && !isAccountId) ||
							(isIdDuplicateCheck !== null && !isIdDuplicateCheck)
						}
						value={join.accountId}
						onBlur={() => JoinStore.validateId()}
						onChange={(event) => {
							JoinStore.validateId();
							JoinStore.setJoin('accountId', event.target.value);
						}}
					/>
					{isAccountId !== null && !isAccountId && (
						<Form.Control.Feedback type="invalid">
							5~20자의 영문 소문자, 숫자, 특수기호(_), (-)만 사용 가능 합니다.
						</Form.Control.Feedback>
					)}
					{isAccountId !== null && isAccountId && isIdDuplicateCheck !== null && !isIdDuplicateCheck && (
						<Form.Control.Feedback type="invalid">이미 사용 중인 ID 입니다.</Form.Control.Feedback>
					)}
					{isAccountId !== null && isAccountId && isIdDuplicateCheck !== null && isIdDuplicateCheck && (
						<Form.Control.Feedback type="valid">사용 가능한 ID 입니다.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						비밀번호 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="password"
						placeholder="비밀번호를 입력해 주세요."
						isValid={isPassword !== null && isPassword}
						isInvalid={isPassword !== null && !isPassword}
						value={join.password}
						onBlur={() => JoinStore.validatePassword()}
						onChange={(event) => {
							JoinStore.setJoin('password', event.target.value);
							JoinStore.validatePassword();
						}}
					/>
					{isPassword !== null && !isPassword && (
						<Form.Control.Feedback type="invalid">
							영문(대소문자), 숫자, 특수문자 중 3가지 이상 사용하여, 8~16자로 입력해 주세요.
						</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						비밀번호 확인 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="password"
						placeholder="비밀번호를 한 번 더 입력해 주세요."
						isValid={isPasswordConfirm !== null && isPasswordConfirm}
						isInvalid={isPasswordConfirm !== null && !isPasswordConfirm}
						value={passwordConfirm}
						onBlur={() => JoinStore.validatePasswordConfirm()}
						onChange={(event) => {
							JoinStore.setPasswordConfirm(event.target.value);
							JoinStore.validatePasswordConfirm();
						}}
					/>
					{isPasswordConfirm !== null && !isPasswordConfirm && (
						<Form.Control.Feedback type="invalid">비밀번호가 일치하지 않습니다.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						프로필 이미지 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<div className="profile-wrap">
						<div>
							{join.profileImage ? (
								<Figure>
									<Figure.Image width={180} alt="img" src={join.profileImage} />
								</Figure>
							) : (
								<div className="empty-img" />
							)}
						</div>
						<Button variant="dark">
							<label htmlFor="file-upload1" style={{ cursor: 'pointer' }}>
								파일 등록
							</label>
							<input
								type="file"
								id="file-upload1"
								className="d-none"
								accept=".jpg, .png, .jpeg, pdf"
								onChange={(event) => {
									handleThumbnailFileDetails(event);
								}}
							/>
						</Button>
					</div>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						이름 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="이름을 입력해 주세요."
						isValid={isName !== null && isName}
						isInvalid={isName !== null && !isName}
						value={join.name}
						onBlur={() => JoinStore.validateName()}
						onChange={(event) => {
							JoinStore.setJoin('name', event.target.value);
							JoinStore.validateName();
						}}
					/>
					{isName !== null && !isName && (
						<Form.Control.Feedback type="invalid">이름을 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						닉네임 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="닉네임을 입력해 주세요."
						isValid={isNickname !== null && isNickname}
						isInvalid={isNickname !== null && !isNickname}
						value={join.nickname}
						onBlur={() => JoinStore.validateNickname()}
						onChange={(event) => {
							JoinStore.setJoin('nickname', event.target.value);
							JoinStore.validateNickname();
						}}
					/>
					{isNickname !== null && !isNickname && (
						<Form.Control.Feedback type="invalid">닉네임을 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						생년월일 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					{/* <Form.Control
						type="date"
						isValid={isBirth !== null && isBirth}
						isInvalid={isBirth !== null && !isBirth}
						value={join.birth}
						onBlur={() => JoinStore.validateBirth()}
						onChange={(event) => {
							console.log(toJS(event));
							JoinStore.setJoin('birth', event.target.value);
							JoinStore.validateBirth();
						}}
						max={new Date().toISOString().substring(0, 10)}
					/> */}
					{/* {isBirth !== null && !isBirth && (
						<Form.Control.Feedback type="invalid">생년월일을 입력해 주세요.</Form.Control.Feedback>
					)} */}
					<DatePicker
						className="date-picker"
						placeholderText="생년월일을 선택해 주세요."
						dateFormat="yyyy-MM-dd"
						selected={join.birth}
						onChange={(event) => {
							if (event !== null) {
								JoinStore.setJoin('birth', event);
								JoinStore.validateBirth();
							}
						}}
						maxDate={new Date()}
						yearDropdownItemNumber={70}
						scrollableYearDropdown
						showYearDropdown
						locale={ko}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						핸드폰 번호 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="핸드폰 번호를 입력해 주세요."
						minLength={13}
						maxLength={13}
						isValid={
							isPhoneNumber !== null && isPhoneNumber && isPhoneNumberCheck !== null && isPhoneNumberCheck
						}
						isInvalid={
							(isPhoneNumber !== null && !isPhoneNumber) ||
							(isPhoneNumberCheck !== null && !isPhoneNumberCheck)
						}
						value={join.phoneNumber}
						onBlur={() => JoinStore.validatePhoneNumber()}
						onChange={(event) => {
							JoinStore.setJoin('phoneNumber', event.target.value);
							JoinStore.validatePhoneNumber();
						}}
					/>
					{isPhoneNumber !== null && !isPhoneNumber && (
						<Form.Control.Feedback type="invalid">휴대폰 번호를 입력해 주세요.</Form.Control.Feedback>
					)}
					{isPhoneNumber !== null && isPhoneNumber && isPhoneNumberCheck !== null && !isPhoneNumberCheck && (
						<Form.Control.Feedback type="invalid">
							휴대폰 번호를 정확하게 입력해 주세요.
						</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						직업 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="직업을 입력해 주세요."
						isValid={isJob !== null && isJob}
						isInvalid={isJob !== null && !isJob}
						value={join.job}
						onBlur={() => JoinStore.validateJob()}
						onChange={(event) => {
							JoinStore.setJoin('job', event.target.value);
							JoinStore.validateJob();
						}}
					/>
					{isJob !== null && !isJob && (
						<Form.Control.Feedback type="invalid">직업을 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						취미 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="text"
						placeholder="취미를 입력해 주세요.      ex) 여행, 서핑, 등산, 공예 등"
						isValid={isHobby !== null && isHobby}
						isInvalid={isHobby !== null && !isHobby}
						value={join.hobby}
						onBlur={() => JoinStore.validateHobby()}
						onChange={(event) => {
							JoinStore.setJoin('hobby', event.target.value);
							JoinStore.validateHobby();
						}}
					/>
					{isHobby !== null && !isHobby && (
						<Form.Control.Feedback type="invalid">취미를 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>
						성별 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<div className="gender-wrap">
						<Form.Check
							type="radio"
							inline
							name="group1"
							label="여성"
							isInvalid={isGender !== null && !isGender}
							checked={join.gender === 'FEMALE'}
							onBlur={() => JoinStore.validateGender()}
							onChange={() => {
								JoinStore.setJoin('gender', 'FEMALE');
								JoinStore.validateGender();
							}}
						/>
						<Form.Check
							type="radio"
							inline
							name="group1"
							label="남성"
							isInvalid={isGender !== null && !isGender}
							checked={join.gender === 'MALE'}
							onBlur={() => JoinStore.validateGender()}
							onChange={() => {
								JoinStore.setJoin('gender', 'MALE');
								JoinStore.validateGender();
							}}
						/>
						{isGender !== null && !isGender && (
							<Form.Control.Feedback type="invalid">성별을 선택해 주세요.</Form.Control.Feedback>
						)}
					</div>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						이메일 주소 <span style={{ color: 'red' }}>*</span>
					</Form.Label>
					<Form.Control
						type="email"
						placeholder="이메일 주소를 입력해 주세요."
						isValid={isEmail !== null && isEmail && isEmailCheck !== null && isEmailCheck}
						isInvalid={(isEmail !== null && !isEmail) || (isEmailCheck !== null && !isEmailCheck)}
						value={join.email}
						onBlur={() => JoinStore.validateEmail()}
						onChange={(event) => {
							JoinStore.validateEmail();
							JoinStore.setJoin('email', event.target.value);
						}}
					/>

					{isEmail !== null && !isEmail && (
						<Form.Control.Feedback type="invalid">이메일을 입력해 주세요.</Form.Control.Feedback>
					)}
					{isEmail !== null && isEmail && isEmailCheck !== null && !isEmailCheck && (
						<Form.Control.Feedback type="invalid">이메일을 정확하게 입력해 주세요.</Form.Control.Feedback>
					)}
				</Form.Group>
				<Form.Group>
					<Form.Label>키</Form.Label>
					<Form.Control
						type="text"
						placeholder="키를 입력해 주세요."
						value={join.height || ''}
						onChange={(event) => JoinStore.setJoin('height', event.target.value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>인스타그램 아이디</Form.Label>
					<Form.Control
						type="text"
						placeholder="인스타그램 아이디를 입력해 주세요."
						value={join.instagramId || ''}
						onChange={(event) => JoinStore.setJoin('instagramId', event.target.value)}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>알게 된 경로</Form.Label>
					<Form.Control
						type="text"
						placeholder="알게 된 경로를 입력해 주세요."
						value={join.knownPath || ''}
						onChange={(event) => {
							JoinStore.setJoin('knownPath', event.target.value);
						}}
					/>
				</Form.Group>
			</Form>
			{JoinStore.isAllValidateFieldsValid() ? (
				<Button
					variant="dark"
					className="mt-5 w-100"
					onClick={() => {
						JoinStore.signUp();
						JoinStore.cleanUp();
						navigate('/login');
					}}>
					회원가입
				</Button>
			) : (
				<Button variant="dark" className="mt-5 w-100" disabled>
					회원가입
				</Button>
			)}
		</Container>
	);
};

export default observer(Join);
