import React from 'react';
import { Row } from 'react-bootstrap';

interface Props {
	children: React.ReactNode;
}

const FormRow: React.FC<Props> = (props) => {
	const { children } = props;

	return <Row className="basic-row">{children}</Row>;
};

export default FormRow;
