import { createContext, useContext } from 'react';
import moment from 'moment';
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import PartyRepository from '../../repositories/admin/PartyRepository';
import { PartyInfoInterface, PartyFormInterface, PaginationInterface } from '../../types/types';
import { initialPartyFormInfo, initialPagination } from '../constants';
import Filter from '../../Filter';
import { Nullable } from '../../types/base';

class PartyStoreImpl {
	partyRepository = PartyRepository();
	filter = Filter();

	pagination: PaginationInterface = initialPagination;
	sort = 'DESC';

	partyList: Array<PartyInfoInterface> = [];
	partyForm: PartyFormInterface = initialPartyFormInfo;
	partyId: Nullable<number> = null;

	successPopup = false;

	aplicationForm: { note: Nullable<string>; partyId: number } = { note: null, partyId: 0 };

	constructor() {
		makeAutoObservable(this);
	}

	cleanUp(): void {
		runInAction(() => {
			this.partyForm = initialPartyFormInfo;
		});
	}

	setPagination(key: string, value: number): void {
		runInAction(() => {
			this.pagination[key] = value;
		});
	}

	setSort(sort: string): void {
		runInAction(() => {
			this.sort = sort;
		});
	}

	setPartyId(id: Nullable<number>): void {
		runInAction(() => {
			this.partyId = id;
		});
	}

	setTitle(title: string): void {
		runInAction(() => {
			this.partyForm.title = title;
		});
	}

	setCategory(category: string): void {
		runInAction(() => {
			this.partyForm.category = category;
		});
	}

	setAddress(address: string): void {
		runInAction(() => {
			this.partyForm.place.address = address;
		});
	}

	setMaleEntryFee(fee: number): void {
		runInAction(() => {
			this.partyForm.entryFee.male = fee;
		});
	}

	setFemaleEntryFee(fee: number): void {
		runInAction(() => {
			this.partyForm.entryFee.female = fee;
		});
	}

	setRecruitmentMale(male: number): void {
		runInAction(() => {
			this.partyForm.recruitment.male = male;
		});
	}

	setRecruitmentFemale(female: number): void {
		runInAction(() => {
			this.partyForm.recruitment.female = female;
		});
	}

	setStartAt(date: string): void {
		runInAction(() => {
			this.partyForm.schedule.startAt = date;
		});
	}

	setEndAt(date: string): void {
		runInAction(() => {
			this.partyForm.schedule.endAt = date;
		});
	}

	setDetailContent(content: string): void {
		runInAction(() => {
			this.partyForm.detailContentHtml = content;
		});
	}

	setMainImageUrl(url: string): void {
		runInAction(() => {
			this.partyForm.mainImageUrl = url;
		});
	}

	setThumbnailUrl(url: string): void {
		runInAction(() => {
			this.partyForm.thumbnailUrl = url;
		});
	}

	setApplicationNote(note: Nullable<string>) {
		runInAction(() => {
			this.aplicationForm.note = note;
		});
	}

	setApplicationPartyId(id: number) {
		runInAction(() => {
			this.aplicationForm.partyId = id;
		});
	}

	openPopup(open: boolean) {
		runInAction(() => {
			this.successPopup = open;
		});
	}

	async getPartyList(): Promise<void> {
		try {
			const { currentPage, size } = this.pagination;
			const query = `?direction=${this.sort}&page=${currentPage}&size=${size}`;
			const { data } = await this.partyRepository.getParties(query);

			runInAction(() => {
				this.partyList = data.data;
				this.setPagination('totalCount', data.total);
				this.setPagination('totalPages', Math.ceil(data.total / size));
			});
		} catch (error) {
			console.log('리스트 조회에 실패하였습니다.');
		}
	}

	async saveParty(): Promise<number | null> {
		try {
			const data = await this.partyRepository.createParty({
				...this.partyForm,
				schedule: {
					startAt: moment(this.partyForm.schedule.startAt).toISOString(),
					endAt: moment(this.partyForm.schedule.endAt).toISOString(),
				},
			});

			return await Promise.resolve(data.data.data);
		} catch (error) {
			console.log('저장에 실패하였습니다.');
			return await Promise.resolve(null);
		}
	}

	async closeParty(id: number): Promise<void> {
		try {
			await this.partyRepository.closeParty(id);
			this.getPartyList();
		} catch (error) {
			console.log('종료에 실패하였습니다.');
		}
	}

	async getParty(): Promise<void> {
		try {
			if (this.partyId) {
				const data = await this.partyRepository.getParty(this.partyId);
				const result = data.data.data;

				this.partyForm = {
					...result,
					schedule: {
						startAt: this.filter
							.dateTimeLocal(result.schedule.startAt)
							.substring(0, result.schedule.startAt.length - 4),
						endAt: this.filter
							.dateTimeLocal(result.schedule.endAt)
							.substring(0, result.schedule.endAt.length - 4),
					},
					recruitment: {
						male: result.recruitment.maleTO,
						female: result.recruitment.femaleTO,
						maleRecruited: result.recruitment.maleRecruited,
						femaleRecruited: result.recruitment.femaleRecruited,
					},
				};
			}
		} catch (error) {
			console.log('조회에 실패하였습니다.');
		}
	}

	async updateParty(): Promise<number | null> {
		try {
			if (this.partyId) {
				const data = await this.partyRepository.updateParty(this.partyId, {
					...this.partyForm,
					schedule: {
						startAt: moment(this.partyForm.schedule.startAt).toISOString(),
						endAt: moment(this.partyForm.schedule.endAt).toISOString(),
					},
				});

				return await Promise.resolve(data.data.data);
			}
			return await Promise.resolve(null);
		} catch (error) {
			console.log('저장에 실패하였습니다.');
			return await Promise.resolve(null);
		}
	}

	async submitApplication(): Promise<void> {
		try {
			const { data } = await this.partyRepository.submitApplication({
				note: this.aplicationForm.note !== null ? this.aplicationForm.note : null,
				partyId: this.aplicationForm.partyId,
			});

			if (data.data) {
				this.openPopup(true);
			}
		} catch (error) {
			alert('이미 신청한 파티입니다.');
		}
	}
}

export const PartyStore = new PartyStoreImpl();
const PartyContext = createContext(PartyStore);
export const usePartyStore = (): PartyStoreImpl => useContext(PartyContext);

reaction(
	() => [PartyStore.pagination.currentPage, PartyStore.pagination.size, PartyStore.sort],
	async () => {
		await PartyStore.getPartyList();
	}
);
