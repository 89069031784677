import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
	options?: any;
	value: string;
	onChange: (content: string) => void;
	disabled?: boolean;
	onFocus?: (value: any) => void;
}

const TinyMCE: React.FC<Props> = (props: Props) => {
	const { options, value, onChange, disabled, onFocus } = props;

	return (
		<>
			<input id="my-file" type="file" name="my-file" style={{ display: 'none' }} />
			<Editor
				apiKey="k8jeber1fh25pso7102lvb9fuuub4s7k5hugc0imlakpmaqg"
				disabled={disabled}
				value={value}
				init={options}
				onEditorChange={(content: any) => onChange(content)}
				onFocus={onFocus}
			/>
		</>
	);
};

const handlerPicker: any = (callback: any, _value: any, meta: any) => {
	if (meta.filetype === 'image') {
		const input = document.getElementById('my-file') as HTMLInputElement;
		if (!input) return;
		input.click();
		input.onchange = () => {
			const file = input.files;

			const reader = new FileReader();
			reader.onload = (e: ProgressEvent<FileReader>) => {
				callback((e.target as FileReader).result, {
					alt: file?.item(0)?.name,
				});
			};
			reader.readAsDataURL(file?.item(0) as Blob);
		};
	}
};

TinyMCE.defaultProps = {
	disabled: false,
	options: {
		selector: 'textarea textarea#file-picker',
		placeholder: '내용을 입력하세요.',
		height: 500,
		plugins: [
			'advlist',
			'autolink',
			'lists',
			'link',
			'image',
			'charmap',
			'print',
			'preview',
			'hr',
			'anchor',
			'pagebreak',
			'searchreplace',
			'wordcount',
			'visualblocks',
			'visualchars',
			'code',
			'fullscreen',
			'insertdatetime',
			'media',
			'nonbreaking',
			'save',
			'table',
			'directionality',
			'emoticons',
			'template',
			'paste',
			'textpattern',
			'codesample',
			'wordcount',
			'quickbars',
		],

		image_title: true,
		automatic_uploads: true,
		file_picker_types: 'file image media',
		file_picker_callback: handlerPicker,
		paste_data_images: true,
		toolbar: [
			'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
			'print preview template | emoticons forecolor backcolor  | codesample  link | image media',
		],
		imagetools_toolbar: 'editimage imageoptions | alignleft aligncenter alignright',
		templates: [
			{
				title: '기본 설정',
				description: '',
				content: `
				<div class="section info-section-2">
<p class="info-section-title"><strong>가자고와 함께해요!</strong></p>
<div class="info-section-desc">
<p>🙋 다양한 분야의 사람들과 소통하고 싶으신 분</p>
<p>🙋 네트워킹 하고싶으신 분</p>
<p>🙋 자연스러운 만남을 원하시는 분</p>
<p>🙋 와인을 좋아하시는 분</p>
<p>🙋 퇴근하고 일상이 무료해서 활력을 주고싶은 분</p>
<p>🙋 대화하기를 좋아하시는 분</p>
<p>🙋 자연스러운 만남으로 연애를 하고싶으신 분</p>
<p>&nbsp;</p>
</div>
</div>
<div class="section info-section-3">
<p class="info-section-title"><strong>제공사항</strong></p>
<div class="info-section-desc">
<p>✅ 간단한 음식과 와인</p>
<p>&nbsp;</p>
</div>
</div>
<div class="section info-section-4">
<p class="info-section-title"><strong>환불규정</strong></p>
<div class="info-section-desc">
<p>1. 신청서를 모두 기입한 후 결제를 진행합니다.</p>
<p>2. 결제 이후 취소 시 수수료가 발생됩니다.<br>- 참석 7일 전 전액 환불<br>- 참석 6일 전 ~ 2일 전 50% 환불<br>- 참석 1일 전 ~ 당일 취소 시 환불 불가</p>
<p>3. 최소 인원 모집이 불가능한 경우, 모임 또는 여행이 취소될 수 있습니다.<br>해당 이유로 모임이 취소될 경우, 결제 금액은 전액 환불됩니다.</p>
<p>4. 환불 방법 - 인스타그램 공식 계정 @gajago_official 으로 문의.<br>환불 기간에 따라 전액 및 부분 환불이 가능합니다.</p>
<p>&nbsp;</p>
<p><strong>입금 계좌</strong></p>
<p>예금주명 : 고소연</p>
<p>국민은행 01250104287423&nbsp;</p>
</div>
</div>`,
			},
		],
		content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
	},
	onFocus: undefined,
};

export default TinyMCE;
