import { CalendarOutlined, EnvironmentOutlined, ManOutlined, WomanOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ListRenderer from '../../components/listRenderer/ListRenderer';
import Filter from '../../Filter';
import PartyRepository from '../../repositories/admin/PartyRepository';
import { PartyInfoInterface } from '../../types/types';

interface Props {
	type: string;
}

const CategoryList: React.FC<Props> = (props) => {
	const { type } = props;
	const [list, setList] = useState<Array<PartyInfoInterface>>([]);

	const { toDate, toTime, dateTimeLocal, getDayOfWeek } = Filter();
	const navigate = useNavigate();

	const { getParties } = PartyRepository();

	const fetchAndHandleData = async (): Promise<void> => {
		try {
			const query = `?direction=DESC&page=1&size=30${type === 'ALL' ? '' : `&category=${type}`}`;
			const { data } = await getParties(query);
			setList(data.data);
		} catch (error) {
			console.log('목록 조회에 실패하였습니다.');
		}
	};

	const getType = (value: string) => {
		switch (value) {
			case 'PARTY':
				return '파티가';
			case 'WINE':
				return '모임이';
			case 'TRIP':
				return '여행이';
			case 'CLASS':
				return '클래스가';
			default:
				return '';
		}
	};

	useEffect(() => {
		fetchAndHandleData();
	}, []);

	return (
		<div className="card-wrap">
			{list.length > 0 ? (
				<ListRenderer
					data={list}
					renderItems={(item) => (
						<Card>
							<Card.Img
								variant="top"
								style={{ width: '100%', height: '330px', objectFit: 'cover' }}
								src={item.thumbnailUrl}
							/>
							<Card.Body>
								<Card.Title>{item.title}</Card.Title>
								<Card.Text>
									<div className="info-wrap">
										<p className="info-title">
											<CalendarOutlined />
										</p>
										<p className="info-desc">
											{toDate(
												dateTimeLocal(item.schedule.startAt).substring(
													0,
													item.schedule.startAt.length - 4
												)
											)}{' '}
											{getDayOfWeek(toDate(dateTimeLocal(item.schedule.startAt)))}{' '}
											{toTime(
												dateTimeLocal(item.schedule.startAt).substring(
													0,
													item.schedule.startAt.length - 4
												)
											)}{' '}
											{parseInt(
												toTime(
													dateTimeLocal(item.schedule.startAt).substring(
														0,
														item.schedule.startAt.length - 7
													)
												),
												10
											) > 12
												? 'PM'
												: 'AM'}
										</p>
									</div>
									<div className="info-wrap">
										<p className="info-title">
											<EnvironmentOutlined />
										</p>
										<p className="info-desc">{item.place.address}</p>
									</div>
									<div className="info-wrap">
										<div className="d-flex me-4">
											<p className="info-title">🙋‍♂️</p>
											<p className="info-desc">
												{item.recruitment.maleRecruited} / {item.recruitment.maleTO}
											</p>
										</div>
										<div className="d-flex">
											<p className="info-title">🙋‍♀️</p>
											<p className="info-desc">
												{item.recruitment.femaleRecruited} / {item.recruitment.femaleTO}
											</p>
										</div>
									</div>
								</Card.Text>
								<Button variant="dark" className="w-100" onClick={() => navigate(`/detail/${item.id}`)}>
									신청하기
								</Button>
							</Card.Body>
						</Card>
					)}
				/>
			) : (
				<div className="w-100 d-flex justify-content-center mt-5">
					<p className="text-center">
						아직 진행 중인 {getType(type)} 없어요!
						<br />
						조금만 기다려 주세요 🥹
					</p>
				</div>
			)}
		</div>
	);
};

export default CategoryList;
